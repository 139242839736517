var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"input-group\" name=\""
    + alias4((((helper = lookupProperty(helpers,"botname") || alias1(depth0, "botname", {"start":{"line":1,"column":33},"end":{"line":1,"column":40}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"botname","hash":{},"data":data,"loc":{"start":{"line":1,"column":31},"end":{"line":1,"column":42}}}) : helper)))
    + "\" id=\""
    + alias4((((helper = lookupProperty(helpers,"botname") || alias1(depth0, "botname", {"start":{"line":1,"column":50},"end":{"line":1,"column":57}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"botname","hash":{},"data":data,"loc":{"start":{"line":1,"column":48},"end":{"line":1,"column":59}}}) : helper)))
    + "_"
    + alias4((((helper = lookupProperty(helpers,"key") || alias1(depth0, "key", {"start":{"line":1,"column":62},"end":{"line":1,"column":65}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":1,"column":60},"end":{"line":1,"column":67}}}) : helper)))
    + "\">\n    <label for=\""
    + alias4((((helper = lookupProperty(helpers,"botname") || alias1(depth0, "botname", {"start":{"line":2,"column":18},"end":{"line":2,"column":25}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"botname","hash":{},"data":data,"loc":{"start":{"line":2,"column":16},"end":{"line":2,"column":27}}}) : helper)))
    + "_"
    + alias4((((helper = lookupProperty(helpers,"key") || alias1(depth0, "key", {"start":{"line":2,"column":30},"end":{"line":2,"column":33}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":2,"column":28},"end":{"line":2,"column":35}}}) : helper)))
    + "_input\" class=\"modal-field-label\">"
    + alias4((((helper = lookupProperty(helpers,"key") || alias1(depth0, "key", {"start":{"line":2,"column":71},"end":{"line":2,"column":74}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":2,"column":69},"end":{"line":2,"column":76}}}) : helper)))
    + "</label>\n    <input type=\"text\" name=\""
    + alias4((((helper = lookupProperty(helpers,"key") || alias1(depth0, "key", {"start":{"line":3,"column":31},"end":{"line":3,"column":34}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":3,"column":29},"end":{"line":3,"column":36}}}) : helper)))
    + "\" id=\""
    + alias4((((helper = lookupProperty(helpers,"botname") || alias1(depth0, "botname", {"start":{"line":3,"column":44},"end":{"line":3,"column":51}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"botname","hash":{},"data":data,"loc":{"start":{"line":3,"column":42},"end":{"line":3,"column":53}}}) : helper)))
    + "_"
    + alias4((((helper = lookupProperty(helpers,"key") || alias1(depth0, "key", {"start":{"line":3,"column":56},"end":{"line":3,"column":59}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":3,"column":54},"end":{"line":3,"column":61}}}) : helper)))
    + "_input\" class=\"modal_text_input\"\n      maxlength=1000 placeholder=\""
    + alias4((((helper = lookupProperty(helpers,"value") || alias1(depth0, "value", {"start":{"line":4,"column":36},"end":{"line":4,"column":41}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":4,"column":34},"end":{"line":4,"column":43}}}) : helper)))
    + "\" value=\"\" />\n</div>\n";
},"useData":true});