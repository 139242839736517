var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-user-id=\""
    + container.escapeExpression((((helper = lookupProperty(helpers,"user_id") || container.strict(depth0, "user_id", {"start":{"line":3,"column":33},"end":{"line":3,"column":40}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":3,"column":31},"end":{"line":3,"column":42}}}) : helper)))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-stream-id=\""
    + container.escapeExpression((((helper = lookupProperty(helpers,"stream_id") || container.strict(depth0, "stream_id", {"start":{"line":4,"column":37},"end":{"line":4,"column":46}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stream_id","hash":{},"data":data,"loc":{"start":{"line":4,"column":35},"end":{"line":4,"column":48}}}) : helper)))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-topic-name=\""
    + container.escapeExpression((((helper = lookupProperty(helpers,"topic_name") || container.strict(depth0, "topic_name", {"start":{"line":5,"column":39},"end":{"line":5,"column":49}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":5,"column":37},"end":{"line":5,"column":51}}}) : helper)))
    + "\"";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p class=\"banner_content\">"
    + container.escapeExpression((((helper = lookupProperty(helpers,"banner_text") || container.strict(depth0, "banner_text", {"start":{"line":8,"column":36},"end":{"line":8,"column":47}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"banner_text","hash":{},"data":data,"loc":{"start":{"line":8,"column":34},"end":{"line":8,"column":49}}}) : helper)))
    + "</p>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"banner_content\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button class=\"main-view-banner-action-button"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"hide_close_button"),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":53},"end":{"line":13,"column":96}}})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"scheduling_message"),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":98},"end":{"line":13,"column":165}}})) != null ? stack1 : "")
    + ">"
    + container.escapeExpression((((helper = lookupProperty(helpers,"button_text") || container.strict(depth0, "button_text", {"start":{"line":13,"column":168},"end":{"line":13,"column":179}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"button_text","hash":{},"data":data,"loc":{"start":{"line":13,"column":166},"end":{"line":13,"column":181}}}) : helper)))
    + "</button>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return " right_edge";
},"14":function(container,depth0,helpers,partials,data) {
    return "data-validation-trigger=\"schedule\"";
},"16":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button class=\"main-view-banner-action-button right_edge\" data-action=\"mark-as-read\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Got it",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":93},"end":{"line":16,"column":107}}}))
    + "</button>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "";
},"20":function(container,depth0,helpers,partials,data) {
    return "    <a role=\"button\" class=\"zulip-icon zulip-icon-close main-view-banner-close-button\"></a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n  class=\"main-view-banner "
    + alias4((((helper = lookupProperty(helpers,"banner_type") || alias1(depth0, "banner_type", {"start":{"line":2,"column":28},"end":{"line":2,"column":39}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"banner_type","hash":{},"data":data,"loc":{"start":{"line":2,"column":26},"end":{"line":2,"column":41}}}) : helper)))
    + " "
    + alias4((((helper = lookupProperty(helpers,"classname") || alias1(depth0, "classname", {"start":{"line":2,"column":44},"end":{"line":2,"column":53}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"classname","hash":{},"data":data,"loc":{"start":{"line":2,"column":42},"end":{"line":2,"column":55}}}) : helper)))
    + "\"\n  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"user_id"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":3,"column":50}}})) != null ? stack1 : "")
    + "\n  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"stream_id"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":4,"column":56}}})) != null ? stack1 : "")
    + "\n  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"topic_name"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":2},"end":{"line":5,"column":59}}})) != null ? stack1 : "")
    + ">\n    <div class=\"main-view-banner-elements-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"banner_text"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":11,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"button_text"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":14,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"is_onboarding_banner"),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":17,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"hide_close_button"),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":23,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});