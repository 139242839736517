var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\"settings-change-box\" class=\"new-style\">\n    "
    + ((stack1 = container.invokePartial(require("./settings/profile_settings.hbs"),depth0,{"name":"settings/profile_settings","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = container.invokePartial(require("./settings/account_settings.hbs"),depth0,{"name":"settings/account_settings","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = container.invokePartial(require("./settings/user_display_settings.hbs"),depth0,{"name":"settings/user_display_settings","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = container.invokePartial(require("./settings/user_notification_settings.hbs"),depth0,{"name":"settings/user_notification_settings","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = container.invokePartial(require("./settings/bot_settings.hbs"),depth0,{"name":"settings/bot_settings","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = container.invokePartial(require("./settings/alert_word_settings.hbs"),depth0,{"name":"settings/alert_word_settings","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = container.invokePartial(require("./settings/attachments_settings.hbs"),depth0,{"name":"settings/attachments_settings","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = container.invokePartial(require("./settings/user_topics_settings.hbs"),depth0,{"name":"settings/user_topics_settings","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = container.invokePartial(require("./settings/muted_users_settings.hbs"),depth0,{"name":"settings/muted_users_settings","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});