var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a class=\"view-all-subscribers-link\" href=\""
    + alias2((((helper = lookupProperty(helpers,"stream_edit_hash") || container.strict(depth0, "stream_edit_hash", {"start":{"line":1,"column":45},"end":{"line":1,"column":61}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"stream_edit_hash","hash":{},"data":data,"loc":{"start":{"line":1,"column":43},"end":{"line":1,"column":63}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"View all subscribers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":65},"end":{"line":1,"column":94}}}))
    + "</a>\n";
},"useData":true});