var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "style=\"display: none;\"";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                You can <z-link-new-bot>add a new bot</z-link-new-bot> or <z-link-manage-bot>manage</z-link-manage-bot> your own bots.\n                \n                \n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a class=\"add-a-new-bot\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/#settings/your-bots\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                You can <z-link>manage</z-link> your own bots.\n                \n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <th class=\"actions\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":36},"end":{"line":36,"column":52}}}))
    + "</th>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"admin-bot-list\" class=\"settings-section\" data-name=\"bot-list-admin\">\n    <div class=\"bot-settings-tip\" id=\"admin-bot-settings-tip\">\n    </div>\n    <div class=\"clear-float\"></div>\n    <div>\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"You are viewing all the bots in this organization.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":67}}}))
    + "\n        <span class=\"add-new-bots\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"can_create_new_bots"),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":35},"end":{"line":7,"column":99}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":12,"column":19}}})) != null ? stack1 : "")
    + "        </span>\n        <span class=\"manage-your-bots\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"can_create_new_bots"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":14,"column":54},"end":{"line":14,"column":79}}}),lookupProperty(depth0,"has_bots"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":14,"column":49},"end":{"line":14,"column":89}}}),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":39},"end":{"line":14,"column":124}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":18,"column":19}}})) != null ? stack1 : "")
    + "        </span>\n    </div>\n    <div class=\"settings_panel_list_header\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Bots",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":22,"column":24}}}))
    + "</h3>\n        <div class=\"alert-notification\" id=\"bot-field-status\"></div>\n        <input type=\"text\" class=\"search filter_text_input\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter bots",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":73},"end":{"line":24,"column":93}}}))
    + "\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter bots",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":107},"end":{"line":24,"column":127}}}))
    + "\"/>\n    </div>\n\n    <div class=\"progressive-table-wrapper\" data-simplebar data-simplebar-tab-index=\"-1\">\n        <table class=\"table table-striped wrapped-table\">\n            <thead class=\"table-sticky-headers\">\n                <th class=\"active\" data-sort=\"alphabetic\" data-sort-prop=\"full_name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":85},"end":{"line":30,"column":98}}}))
    + "</th>\n                <th class=\"settings-email-column\" data-sort=\"email\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":68},"end":{"line":31,"column":82}}}))
    + "</th>\n                <th class=\"user_role\" data-sort=\"role\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Role",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":55},"end":{"line":32,"column":68}}}))
    + "</th>\n                <th data-sort=\"bot_owner\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Owner",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":42},"end":{"line":33,"column":56}}}))
    + "</th>\n                <th data-sort=\"alphabetic\" data-sort-prop=\"bot_type\" class=\"bot_type\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Bot type",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":86},"end":{"line":34,"column":103}}}))
    + "</th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":16},"end":{"line":37,"column":23}}})) != null ? stack1 : "")
    + "            </thead>\n            <tbody id=\"admin_bots_table\" class=\"admin_bot_table\"\n              data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"There are no bots.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":26},"end":{"line":40,"column":53}}}))
    + "\" data-search-results-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No bots match your current filter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":82},"end":{"line":40,"column":125}}}))
    + "\"></tbody>\n        </table>\n    </div>\n    <div id=\"admin_page_bots_loading_indicator\"></div>\n</div>\n";
},"3_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link-new-bot"],"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":10,"column":105}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link-manage-bot"],"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":114}}}) || fn;
  return fn;
  }

,"useDecorators":true,"8_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link"],"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":17,"column":103}}}) || fn;
  return fn;
  }

,"usePartial":true,"useData":true,"useDepths":true});