var Handlebars = require("../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu user-card-popover-actions no-auto-hide-right-sidebar-overlay\" id=\"user_card_popover\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <div class=\"popover-menu-user-header\">\n        <div class=\"popover-menu-user-avatar-container\">\n            <img class=\"popover-menu-user-avatar\" src=\""
    + alias4((((helper = lookupProperty(helpers,"user_avatar") || alias1(depth0, "user_avatar", {"start":{"line":4,"column":57},"end":{"line":4,"column":68}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"user_avatar","hash":{},"data":data,"loc":{"start":{"line":4,"column":55},"end":{"line":4,"column":70}}}) : helper)))
    + "\" />\n        </div>\n        <div class=\"popover-menu-user-info\">\n            <div class=\"popover-menu-user-full-name\" data-tippy-content=\""
    + alias4(lookupProperty(helpers,"t").call(alias2,"Unknown user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":73},"end":{"line":7,"column":94}}}))
    + "\">\n                "
    + alias4(lookupProperty(helpers,"t").call(alias2,"Unknown user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":37}}}))
    + "\n            </div>\n        </div>\n    </div>\n    <ul role=\"menu\" class=\"popover-menu-list\" data-user-id=\""
    + alias4((((helper = lookupProperty(helpers,"user_id") || alias1(depth0, "user_id", {"start":{"line":12,"column":62},"end":{"line":12,"column":69}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":12,"column":60},"end":{"line":12,"column":71}}}) : helper)))
    + "\">\n        <li role=\"separator\" class=\"popover-menu-separator hidden-for-spectators\"></li>\n        <li role=\"none\" class=\"popover-menu-list-item link-item\">\n            <a role=\"menuitem\" href=\""
    + alias4((((helper = lookupProperty(helpers,"sent_by_url") || alias1(depth0, "sent_by_url", {"start":{"line":15,"column":40},"end":{"line":15,"column":51}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"sent_by_url","hash":{},"data":data,"loc":{"start":{"line":15,"column":37},"end":{"line":15,"column":54}}}) : helper)))
    + "\" class=\"narrow_to_messages_sent popover-menu-link hidden-for-spectators\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-message-square\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias4(lookupProperty(helpers,"t").call(alias2,"View messages sent",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":49},"end":{"line":17,"column":76}}}))
    + "</span>\n            </a>\n        </li>\n    </ul>\n</div>\n";
},"useData":true});