var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " active-user";
},"3":function(container,depth0,helpers,partials,data) {
    return " deactivated_user";
},"5":function(container,depth0,helpers,partials,data) {
    return "    <td class=\"email settings-email-column\">\n        <span class=\"email\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "display_email", {"start":{"line":7,"column":30},"end":{"line":7,"column":43}} ), depth0))
    + "</span>\n    </td>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"email settings-email-column\">\n        <span class=\"hidden-email\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"(hidden)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":35},"end":{"line":11,"column":51}}}))
    + "</span>\n    </td>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td>\n        <span class=\"owner panel_user_list\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"no_owner"),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":24,"column":19}}})) != null ? stack1 : "")
    + "        </span>\n    </td>\n    <td class=\"bot_type\">\n        <span class=\"bot type\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "bot_type", {"start":{"line":28,"column":33},"end":{"line":28,"column":41}} ), depth0))
    + "</span>\n    </td>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "            "
    + container.escapeExpression(container.lambda(container.strict(depth0, "bot_owner_full_name", {"start":{"line":21,"column":14},"end":{"line":21,"column":33}} ), depth0))
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.invokePartial(require("../user_display_only_pill.hbs"),depth0,{"name":"../user_display_only_pill","hash":{"is_active":lookupProperty(depth0,"is_bot_owner_active"),"img_src":lookupProperty(depth0,"owner_img_src"),"user_id":lookupProperty(depth0,"bot_owner_id"),"display_value":lookupProperty(depth0,"bot_owner_full_name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"display_last_active_column"),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":4},"end":{"line":34,"column":4}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    return "    <td class=\"last_active\">\n        "
    + container.escapeExpression(container.lambda(container.strict(depth0, "last_active_date", {"start":{"line":32,"column":11},"end":{"line":32,"column":27}} ), depth0))
    + "\n    </td>\n    ";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"actions\">\n        <span class=\"user-status-settings\">\n            <span "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depth0,"is_bot"),lookupProperty(depth0,"cannot_edit"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":38,"column":24},"end":{"line":38,"column":48}}}),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":18},"end":{"line":38,"column":84}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depth0,"is_bot"),lookupProperty(depth0,"cannot_edit"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":38,"column":91},"end":{"line":38,"column":115}}}),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":85},"end":{"line":38,"column":179}}})) != null ? stack1 : "")
    + ">\n                <button class=\"button rounded small btn-warning open-user-form tippy-zulip-delayed-tooltip\" data-tippy-content=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":39,"column":128},"end":{"line":39,"column":226}}})) != null ? stack1 : "")
    + "\" data-user-id=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "user_id", {"start":{"line":39,"column":244},"end":{"line":39,"column":251}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"cannot_edit"),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":255},"end":{"line":39,"column":300}}})) != null ? stack1 : "")
    + ">\n                    <i class=\"fa fa-pencil\" aria-hidden=\"true\"></i>\n                </button>\n            </span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_active"),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.program(34, data, 0),"data":data,"loc":{"start":{"line":43,"column":12},"end":{"line":53,"column":19}}})) != null ? stack1 : "")
    + "        </span>\n    </td>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "class=\"tippy-zulip-tooltip\"";
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-tippy-content=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"This bot cannot be edited.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":137},"end":{"line":38,"column":171}}}))
    + "\"";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"cannot_edit"),{"name":"unless","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":142},"end":{"line":39,"column":193}}})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Edit bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":165},"end":{"line":39,"column":182}}}));
},"25":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Edit user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":201},"end":{"line":39,"column":219}}}));
},"27":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depth0,"is_bot"),lookupProperty(depth0,"cannot_deactivate"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":44,"column":24},"end":{"line":44,"column":54}}}),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":18},"end":{"line":44,"column":90}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depth0,"is_bot"),lookupProperty(depth0,"cannot_deactivate"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":44,"column":97},"end":{"line":44,"column":127}}}),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":91},"end":{"line":44,"column":196}}})) != null ? stack1 : "")
    + ">\n                <button class=\"button rounded small btn-danger "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depth0,"is_bot"),lookupProperty(depth0,"cannot_deactivate"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":45,"column":73},"end":{"line":45,"column":103}}}),{"name":"unless","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":63},"end":{"line":45,"column":127}}})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"cannot_deactivate"),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":129},"end":{"line":45,"column":180}}})) != null ? stack1 : "")
    + ">\n                    <i class=\"fa fa-user-times\" aria-hidden=\"true\"></i>\n                </button>\n            </span>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-tippy-content=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"This bot cannot be deactivated.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":149},"end":{"line":44,"column":188}}}))
    + "\"";
},"32":function(container,depth0,helpers,partials,data) {
    return "deactivate";
},"34":function(container,depth0,helpers,partials,data) {
    return "            <button class=\"button rounded small reactivate btn-warning\">\n                <i class=\"fa fa-user-plus\" aria-hidden=\"true\"></i>\n            </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"user_row"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_active"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":19},"end":{"line":1,"column":80}}})) != null ? stack1 : "")
    + "\" data-user-id=\""
    + alias4(alias3(alias2(depth0, "user_id", {"start":{"line":1,"column":98},"end":{"line":1,"column":105}} ), depth0))
    + "\">\n    <td class=\"user_name panel_user_list\">\n        "
    + ((stack1 = container.invokePartial(require("../user_display_only_pill.hbs"),depth0,{"name":"../user_display_only_pill","hash":{"img_src":lookupProperty(depth0,"img_src"),"user_id":lookupProperty(depth0,"user_id"),"display_value":lookupProperty(depth0,"full_name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"display_email"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + "    <td>\n        <span class=\"user_role\">"
    + alias4(alias3(alias2(depth0, "user_role_text", {"start":{"line":15,"column":34},"end":{"line":15,"column":48}} ), depth0))
    + "</span>\n    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":34,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_modify"),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":4},"end":{"line":56,"column":11}}})) != null ? stack1 : "")
    + "</tr>\n";
},"usePartial":true,"useData":true});