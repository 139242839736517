var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"micromodal\" id=\"read_receipts_modal\" aria-hidden=\"true\" data-message-id=\""
    + alias2((((helper = lookupProperty(helpers,"message_id") || container.strict(depth0, "message_id", {"start":{"line":1,"column":87},"end":{"line":1,"column":97}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":1,"column":85},"end":{"line":1,"column":99}}}) : helper)))
    + "\">\n    <div class=\"modal__overlay\" tabindex=\"-1\">\n        <div class=\"modal__container\" role=\"dialog\" aria-modal=\"true\" aria-labelledby=\"read_receipts_modal_label\">\n            <header class=\"modal__header\">\n                <h1 class=\"modal__title\" id=\"read_receipts_modal_label\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Read receipts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":42}}}))
    + "\n                </h1>\n                <button class=\"modal__close\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Close modal",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":57},"end":{"line":8,"column":77}}}))
    + "\" data-micromodal-close></button>\n            </header>\n            <hr/>\n            <main class=\"modal__content\">\n                <div class=\"alert\" id=\"read_receipts_error\"></div>\n                <div class=\"read_receipts_info\">\n                </div>\n                <div class=\"loading_indicator\"></div>\n                <ul class=\"read_receipts_list\"></ul>\n            </main>\n        </div>\n    </div>\n</div>\n";
},"useData":true});