var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <a class=\"above_compose_banner_action_link\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"above_composebox_narrow_url"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":70},"end":{"line":4,"column":150}}})) != null ? stack1 : "")
    + " data-message-id=\""
    + alias4((((helper = lookupProperty(helpers,"link_msg_id") || alias2(depth0, "link_msg_id", {"start":{"line":4,"column":170},"end":{"line":4,"column":181}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"link_msg_id","hash":{},"data":data,"loc":{"start":{"line":4,"column":168},"end":{"line":4,"column":183}}}) : helper)))
    + "\">"
    + alias4((((helper = lookupProperty(helpers,"link_text") || alias2(depth0, "link_text", {"start":{"line":4,"column":187},"end":{"line":4,"column":196}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"link_text","hash":{},"data":data,"loc":{"start":{"line":4,"column":185},"end":{"line":4,"column":198}}}) : helper)))
    + "</a>";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "href=\""
    + container.escapeExpression((((helper = lookupProperty(helpers,"above_composebox_narrow_url") || container.strict(depth0, "above_composebox_narrow_url", {"start":{"line":4,"column":113},"end":{"line":4,"column":140}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"above_composebox_narrow_url","hash":{},"data":data,"loc":{"start":{"line":4,"column":111},"end":{"line":4,"column":142}}}) : helper)))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"above_compose_banner main-view-banner success "
    + alias4((((helper = lookupProperty(helpers,"classname") || alias1(depth0, "classname", {"start":{"line":1,"column":60},"end":{"line":1,"column":69}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"classname","hash":{},"data":data,"loc":{"start":{"line":1,"column":58},"end":{"line":1,"column":71}}}) : helper)))
    + "\">\n    <p class=\"banner_content\">\n        "
    + alias4((((helper = lookupProperty(helpers,"banner_text") || alias1(depth0, "banner_text", {"start":{"line":3,"column":10},"end":{"line":3,"column":21}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"banner_text","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":23}}}) : helper)))
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"link_text"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":209}}})) != null ? stack1 : "")
    + "\n    </p>\n    <a role=\"button\" class=\"zulip-icon zulip-icon-close main-view-banner-close-button\"></a>\n</div>\n";
},"useData":true});