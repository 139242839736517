var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((((helper = lookupProperty(helpers,"remaining_characters") || container.strict(depth0, "remaining_characters", {"start":{"line":1,"column":2},"end":{"line":1,"column":22}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"remaining_characters","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":24}}}) : helper)))
    + "\n";
},"useData":true});