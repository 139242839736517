var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "surround-formatting-buttons-row";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"message-edit-feature-group\">\n                "
    + ((stack1 = container.invokePartial(require("./compose_control_buttons.hbs"),depth0,{"name":"compose_control_buttons","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"message_edit_save_container\"\n                      data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You can no longer save changes to this message.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":42},"end":{"line":23,"column":98}}}))
    + "\">\n                        <button type=\"button\" class=\"message-actions-button message_edit_save\">\n                            <img class=\"loader\" alt=\"\" src=\"\" />\n                            <span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Save",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":34},"end":{"line":26,"column":47}}}))
    + "</span>\n                        </button>\n                    </div>\n                    <button type=\"button\" class=\"message-actions-button message_edit_cancel\"><span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":99},"end":{"line":29,"column":114}}}))
    + "</span></button>\n                    <div class=\"message-edit-timer\">\n                        <span class=\"message_edit_countdown_timer\n                          tippy-zulip-tooltip\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"This organization is configured to restrict editing of message content to {minutes_to_edit} minutes after it is sent.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":67},"end":{"line":32,"column":193}}}))
    + "\"></span>\n                    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"message-actions-button message_edit_close\"><span>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Close",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":98},"end":{"line":35,"column":112}}}))
    + "</span></button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<form id=\"edit_form_"
    + alias3(alias2(alias1(depth0, "message_id", {"start":{"line":3,"column":22},"end":{"line":3,"column":32}} ), depth0))
    + "\" class=\"new-style\">\n    <div class=\"edit_form_banners\"></div>\n    <div class=\"edit-controls edit-content-container "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_editable"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":53},"end":{"line":5,"column":110}}})) != null ? stack1 : "")
    + "\">\n        "
    + ((stack1 = container.invokePartial(require("./copy_message_button.hbs"),depth0,{"name":"copy_message_button","hash":{"message_id":lookupProperty(depth0,"message_id")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        <textarea class=\"message_edit_content\" maxlength=\""
    + alias3(alias2(alias1(depth0, "max_message_length", {"start":{"line":7,"column":61},"end":{"line":7,"column":79}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "content", {"start":{"line":7,"column":86},"end":{"line":7,"column":93}} ), depth0))
    + "</textarea>\n        <div class=\"scrolling_list preview_message_area\" id=\"preview_message_area_"
    + alias3(alias2(alias1(depth0, "message_id", {"start":{"line":8,"column":84},"end":{"line":8,"column":94}} ), depth0))
    + "\" style=\"display:none;\">\n            <div class=\"markdown_preview_spinner\"></div>\n            <div class=\"preview_content rendered_markdown\"></div>\n        </div>\n    </div>\n    <div class=\"action-buttons\">\n        <div class=\"controls edit-controls\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_editable"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":19,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"message-edit-buttons-and-timer\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_editable"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":36,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</form>\n";
},"usePartial":true,"useData":true});