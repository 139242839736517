var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"type"),"plain_text",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":3,"column":10},"end":{"line":3,"column":37}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":54,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"not").call(alias1,lookupProperty(data,"last"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":55,"column":11},"end":{"line":55,"column":22}}}),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":4},"end":{"line":55,"column":35}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(container.strict(depth0, "content", {"start":{"line":4,"column":11},"end":{"line":4,"column":23}} ), depth0));
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"type"),"channel_topic",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":5,"column":14},"end":{"line":5,"column":44}}}),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":54,"column":4}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "channel "
    + alias3(alias2(alias1(depth0, "channel", {"start":{"line":7,"column":18},"end":{"line":7,"column":30}} ), depth0))
    + " > "
    + alias3(alias2(alias1(depth0, "topic", {"start":{"line":7,"column":37},"end":{"line":7,"column":47}} ), depth0));
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"type"),"invalid_has",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":9,"column":14},"end":{"line":9,"column":42}}}),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":54,"column":4}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "invalid "
    + container.escapeExpression(container.lambda(container.strict(depth0, "operand", {"start":{"line":11,"column":18},"end":{"line":11,"column":30}} ), depth0))
    + " operand for has operator";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"type"),"prefix_for_operator",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":13,"column":14},"end":{"line":13,"column":50}}}),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":54,"column":4}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias3(alias2(alias1(depth0, "prefix_for_operator", {"start":{"line":15,"column":10},"end":{"line":15,"column":34}} ), depth0))
    + " "
    + alias3(alias2(alias1(depth0, "operand", {"start":{"line":15,"column":39},"end":{"line":15,"column":51}} ), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"or").call(alias4,lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"operand"),"link",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":15,"column":63},"end":{"line":15,"column":87}}}),lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"operand"),"image",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":15,"column":88},"end":{"line":15,"column":113}}}),lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"operand"),"attachment",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":15,"column":114},"end":{"line":15,"column":144}}}),lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"operand"),"reaction",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":15,"column":145},"end":{"line":15,"column":173}}}),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":15,"column":59},"end":{"line":15,"column":174}}}),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":53},"end":{"line":15,"column":184}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    return "s";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"type"),"user_pill",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":17,"column":14},"end":{"line":17,"column":40}}}),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":54,"column":4}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(container.strict(depth0, "operator", {"start":{"line":19,"column":10},"end":{"line":19,"column":23}} ), depth0))
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"users"),{"name":"each","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":26,"column":19}}})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"valid_user"),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":25,"column":19}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = container.invokePartial(require("./user_pill.hbs"),depth0,{"name":"user_pill","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    return "                "
    + container.escapeExpression(container.lambda(container.strict(depth0, "operand", {"start":{"line":24,"column":18},"end":{"line":24,"column":30}} ), depth0))
    + "\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"type"),"is_operator",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":28,"column":14},"end":{"line":28,"column":42}}}),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":4},"end":{"line":54,"column":4}}})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"operand"),"mentioned",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":29,"column":14},"end":{"line":29,"column":43}}}),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":29,"column":8},"end":{"line":53,"column":17}}})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(container.strict(depth0, "verb", {"start":{"line":31,"column":14},"end":{"line":31,"column":23}} ), depth0))
    + "@-mentions";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"operand"),"starred",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":33,"column":22},"end":{"line":33,"column":49}}}),lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"operand"),"alerted",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":33,"column":50},"end":{"line":33,"column":77}}}),lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"operand"),"unread",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":33,"column":78},"end":{"line":33,"column":104}}}),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":33,"column":18},"end":{"line":33,"column":105}}}),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":53,"column":8}}})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return alias3(alias2(alias1(depth0, "verb", {"start":{"line":35,"column":14},"end":{"line":35,"column":23}} ), depth0))
    + alias3(alias2(alias1(depth0, "operand", {"start":{"line":35,"column":27},"end":{"line":35,"column":39}} ), depth0))
    + " messages";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"operand"),"dm",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":37,"column":22},"end":{"line":37,"column":44}}}),lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"operand"),"private",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":37,"column":45},"end":{"line":37,"column":72}}}),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":37,"column":18},"end":{"line":37,"column":73}}}),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.program(31, data, 0),"data":data,"loc":{"start":{"line":37,"column":8},"end":{"line":53,"column":8}}})) != null ? stack1 : "");
},"29":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(container.strict(depth0, "verb", {"start":{"line":39,"column":14},"end":{"line":39,"column":23}} ), depth0))
    + "direct messages";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"operand"),"resolved",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":41,"column":18},"end":{"line":41,"column":46}}}),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.program(34, data, 0),"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":53,"column":8}}})) != null ? stack1 : "");
},"32":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(container.strict(depth0, "verb", {"start":{"line":43,"column":14},"end":{"line":43,"column":23}} ), depth0))
    + "topics marked as resolved";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"operand"),"followed",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":45,"column":18},"end":{"line":45,"column":46}}}),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.program(37, data, 0),"data":data,"loc":{"start":{"line":45,"column":8},"end":{"line":53,"column":8}}})) != null ? stack1 : "");
},"35":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(container.strict(depth0, "verb", {"start":{"line":47,"column":14},"end":{"line":47,"column":23}} ), depth0))
    + "followed topics";
},"37":function(container,depth0,helpers,partials,data) {
    return "invalid "
    + container.escapeExpression(container.lambda(container.strict(depth0, "operand", {"start":{"line":51,"column":22},"end":{"line":51,"column":34}} ), depth0))
    + " operand for is operator";
},"39":function(container,depth0,helpers,partials,data) {
    return ", ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"parts"),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":57,"column":11}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});