var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"copy_button_base copy_message tippy-zulip-tooltip\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Copy and close",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":83},"end":{"line":1,"column":106}}}))
    + "\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Copy and close",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":120},"end":{"line":1,"column":143}}}))
    + "\" role=\"button\">\n    "
    + ((stack1 = container.invokePartial(require("./copy_to_clipboard_svg.hbs"),depth0,{"name":"copy_to_clipboard_svg","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});