var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"hide\" id=\"user-group-creation\" tabindex=\"-1\" role=\"dialog\"\n  aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"User group creation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":14},"end":{"line":2,"column":42}}}))
    + "\">\n    <form id=\"user_group_creation_form\">\n        <div class=\"user-group-creation-simplebar-container\" data-simplebar data-simplebar-tab-index=\"-1\">\n            <div class=\"alert user_group_create_info\"></div>\n            <div id=\"user_group_creating_indicator\"></div>\n            <div class=\"user-group-creation-body\">\n                <div class=\"configure_user_group_settings user_group_creation\">\n                    <section id=\"user-group-name-container\" class=\"block\">\n                        <label for=\"create_user_group_name\" class=\"settings-field-label\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"User group name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":28},"end":{"line":11,"column":52}}}))
    + "\n                        </label>\n                        <input type=\"text\" name=\"user_group_name\" id=\"create_user_group_name\" class=\"settings_text_input\"\n                          placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"User group name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":39},"end":{"line":14,"column":63}}}))
    + "\" value=\"\" autocomplete=\"off\" maxlength=\""
    + alias2(container.lambda(container.strict(depth0, "max_user_group_name_length", {"start":{"line":14,"column":107},"end":{"line":14,"column":133}} ), depth0))
    + "\"/>\n                        <div id=\"user_group_name_error\" class=\"user_group_creation_error\"></div>\n                    </section>\n                    <section id=\"user-group-description-container\" class=\"block\">\n                        <label for=\"create_user_group_description\" class=\"settings-field-label\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"User group description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":28},"end":{"line":19,"column":59}}}))
    + "\n                        </label>\n                        <input type=\"text\" name=\"user_group_description\" id=\"create_user_group_description\" class=\"settings_text_input\"\n                          placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"User group description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":39},"end":{"line":22,"column":70}}}))
    + "\" value=\"\" autocomplete=\"off\" />\n                    </section>\n                    <section id=\"user-group-permission-container\" class=\"block\">\n                        <div class=\"group-permissions settings-subsection-parent\" id=\"new_group_permission_settings\">\n                            <div class=\"subsection-header\">\n                                <h3 class=\"user_group_setting_subsection_title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Group permissions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":80},"end":{"line":27,"column":106}}}))
    + "\n                                </h3>\n                            </div>\n\n                            "
    + ((stack1 = container.invokePartial(require("./group_permissions.hbs"),depth0,{"name":"group_permissions","hash":{"can_mention_group_widget_name":"new_group_can_mention_group"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\n                    </section>\n                </div>\n                <div class=\"user_group_members_container user_group_creation\">\n                    <section id=\"choose_member_section\" class=\"block\">\n                        <label for=\"people_to_add_in_group\">\n                            <h4 class=\"user_group_setting_subsection_title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Choose members",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":76},"end":{"line":38,"column":99}}}))
    + "</h4>\n                        </label>\n                        <div id=\"user_group_membership_error\" class=\"user_group_creation_error\"></div>\n                        <div class=\"controls\" id=\"people_to_add_in_group\"></div>\n                    </section>\n                </div>\n            </div>\n        </div>\n        <div class=\"settings-sticky-footer\">\n            <div class=\"settings-sticky-footer-left\">\n                <button id=\"user_group_go_to_configure_settings\" class=\"button small sea-green rounded hide\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Back to settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":109},"end":{"line":48,"column":134}}}))
    + "</button>\n            </div>\n            <div class=\"settings-sticky-footer-right\">\n                <button class=\"create_user_group_cancel button small white rounded\" data-dismiss=\"modal\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":105},"end":{"line":51,"column":120}}}))
    + "</button>\n                <button class=\"finalize_create_user_group button small sea-green rounded hide\" type=\"submit\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Create",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":109},"end":{"line":52,"column":124}}}))
    + "</button>\n                <button id=\"user_group_go_to_members\" class=\"button small sea-green rounded\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Continue to add members",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":93},"end":{"line":53,"column":125}}}))
    + "</button>\n            </div>\n        </div>\n    </form>\n</div>\n";
},"usePartial":true,"useData":true});