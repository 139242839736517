var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\"admin-user-list\" class=\"settings-section\" data-name=\"users\">\n\n    <div class=\"tab-container\"></div>\n\n    "
    + ((stack1 = container.invokePartial(require("./active_user_list_admin.hbs"),depth0,{"name":"active_user_list_admin","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = container.invokePartial(require("./deactivated_users_admin.hbs"),depth0,{"name":"deactivated_users_admin","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = container.invokePartial(require("./invites_list_admin.hbs"),depth0,{"name":"invites_list_admin","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n</div>\n";
},"usePartial":true,"useData":true});