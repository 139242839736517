var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"playground_row\">\n    <td>\n        <span class=\"playground_pygments_language\">"
    + alias4((((helper = lookupProperty(helpers,"pygments_language") || alias1(depth0, "pygments_language", {"start":{"line":4,"column":53},"end":{"line":4,"column":70}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"pygments_language","hash":{},"data":data,"loc":{"start":{"line":4,"column":51},"end":{"line":4,"column":72}}}) : helper)))
    + "</span>\n    </td>\n    <td>\n        <span class=\"playground_name\">"
    + alias4((((helper = lookupProperty(helpers,"playground_name") || alias1(depth0, "playground_name", {"start":{"line":7,"column":40},"end":{"line":7,"column":55}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"playground_name","hash":{},"data":data,"loc":{"start":{"line":7,"column":38},"end":{"line":7,"column":57}}}) : helper)))
    + "</span>\n    </td>\n    <td>\n        <span class=\"playground_url_template\">"
    + alias4((((helper = lookupProperty(helpers,"url_template") || alias1(depth0, "url_template", {"start":{"line":10,"column":48},"end":{"line":10,"column":60}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"url_template","hash":{},"data":data,"loc":{"start":{"line":10,"column":46},"end":{"line":10,"column":62}}}) : helper)))
    + "</span>\n    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depths[1],"can_modify"),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":18,"column":11}}})) != null ? stack1 : "")
    + "</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"no-select actions\">\n        <button class=\"button small delete btn-danger tippy-zulip-tooltip\" data-playground-id=\""
    + alias4((((helper = lookupProperty(helpers,"id") || alias1(depth0, "id", {"start":{"line":14,"column":97},"end":{"line":14,"column":99}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":14,"column":95},"end":{"line":14,"column":101}}}) : helper)))
    + "\" data-tippy-content=\""
    + alias4(lookupProperty(helpers,"t").call(alias2,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":123},"end":{"line":14,"column":138}}}))
    + " "
    + alias4((((helper = lookupProperty(helpers,"playground_name") || alias1(depth0, "playground_name", {"start":{"line":14,"column":142},"end":{"line":14,"column":157}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"playground_name","hash":{},"data":data,"loc":{"start":{"line":14,"column":139},"end":{"line":14,"column":160}}}) : helper)))
    + "\" aria-label=\""
    + alias4(lookupProperty(helpers,"t").call(alias2,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":174},"end":{"line":14,"column":189}}}))
    + " "
    + alias4((((helper = lookupProperty(helpers,"playground_name") || alias1(depth0, "playground_name", {"start":{"line":14,"column":193},"end":{"line":14,"column":208}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"playground_name","hash":{},"data":data,"loc":{"start":{"line":14,"column":190},"end":{"line":14,"column":211}}}) : helper)))
    + "\">\n            <i class=\"fa fa-trash-o\"></i>\n        </button>\n    </td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"playground"),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":20,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});