var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"You do not have permission to move some of the messages in this topic. Contact a moderator to move all messages.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":3},"end":{"line":1,"column":123}}}))
    + "\n</p>\n<p>\n    "
    + alias2((((helper = lookupProperty(helpers,"messages_allowed_to_move_text") || alias3(depth0, "messages_allowed_to_move_text", {"start":{"line":4,"column":6},"end":{"line":4,"column":35}} ))),(typeof helper === alias4 ? helper.call(alias1,{"name":"messages_allowed_to_move_text","hash":{},"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":37}}}) : helper)))
    + "\n    "
    + alias2((((helper = lookupProperty(helpers,"messages_not_allowed_to_move_text") || alias3(depth0, "messages_not_allowed_to_move_text", {"start":{"line":5,"column":6},"end":{"line":5,"column":39}} ))),(typeof helper === alias4 ? helper.call(alias1,{"name":"messages_not_allowed_to_move_text","hash":{},"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":41}}}) : helper)))
    + "\n</p>\n";
},"useData":true});