var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr data-stream-id=\""
    + alias4((((helper = lookupProperty(helpers,"stream_id") || alias1(depth0, "stream_id", {"start":{"line":2,"column":22},"end":{"line":2,"column":31}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"stream_id","hash":{},"data":data,"loc":{"start":{"line":2,"column":20},"end":{"line":2,"column":33}}}) : helper)))
    + "\" data-stream=\""
    + alias4((((helper = lookupProperty(helpers,"stream") || alias1(depth0, "stream", {"start":{"line":2,"column":50},"end":{"line":2,"column":56}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"stream","hash":{},"data":data,"loc":{"start":{"line":2,"column":48},"end":{"line":2,"column":58}}}) : helper)))
    + "\" data-topic=\""
    + alias4((((helper = lookupProperty(helpers,"topic") || alias1(depth0, "topic", {"start":{"line":2,"column":74},"end":{"line":2,"column":79}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"topic","hash":{},"data":data,"loc":{"start":{"line":2,"column":72},"end":{"line":2,"column":81}}}) : helper)))
    + "\" data-date-updated=\""
    + alias4((((helper = lookupProperty(helpers,"date_updated_str") || alias1(depth0, "date_updated_str", {"start":{"line":2,"column":104},"end":{"line":2,"column":120}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"date_updated_str","hash":{},"data":data,"loc":{"start":{"line":2,"column":102},"end":{"line":2,"column":122}}}) : helper)))
    + "\" data-visibility-policy=\""
    + alias4((((helper = lookupProperty(helpers,"visibility_policy") || alias1(depth0, "visibility_policy", {"start":{"line":2,"column":150},"end":{"line":2,"column":167}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"visibility_policy","hash":{},"data":data,"loc":{"start":{"line":2,"column":148},"end":{"line":2,"column":169}}}) : helper)))
    + "\">\n    <td class=\"user-topic-stream\">"
    + alias4((((helper = lookupProperty(helpers,"stream") || alias1(depth0, "stream", {"start":{"line":3,"column":36},"end":{"line":3,"column":42}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"stream","hash":{},"data":data,"loc":{"start":{"line":3,"column":34},"end":{"line":3,"column":44}}}) : helper)))
    + "</td>\n    <td class=\"white-space-preserve-wrap user-topic\">"
    + alias4((((helper = lookupProperty(helpers,"topic") || alias1(depth0, "topic", {"start":{"line":4,"column":55},"end":{"line":4,"column":60}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"topic","hash":{},"data":data,"loc":{"start":{"line":4,"column":53},"end":{"line":4,"column":62}}}) : helper)))
    + "</td>\n    <td>\n        <select class=\"settings_user_topic_visibility_policy list_select bootstrap-focus-style\" data-setting-widget-type=\"number\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias2,lookupProperty(depths[1],"user_topic_visibility_policy_values"),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":9,"column":21}}})) != null ? stack1 : "")
    + "        </select>\n    </td>\n    <td class=\"topic_date_updated\">"
    + alias4((((helper = lookupProperty(helpers,"date_updated_str") || alias1(depth0, "date_updated_str", {"start":{"line":12,"column":37},"end":{"line":12,"column":53}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"date_updated_str","hash":{},"data":data,"loc":{"start":{"line":12,"column":35},"end":{"line":12,"column":55}}}) : helper)))
    + "</td>\n</tr>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value='"
    + alias3(alias2(alias1(depth0, "code", {"start":{"line":8,"column":33},"end":{"line":8,"column":42}} ), depth0))
    + "' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"code"),lookupProperty(depths[1],"visibility_policy"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":8,"column":52},"end":{"line":8,"column":87}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":46},"end":{"line":8,"column":104}}})) != null ? stack1 : "")
    + ">"
    + alias3(alias2(alias1(depth0, "description", {"start":{"line":8,"column":107},"end":{"line":8,"column":123}} ), depth0))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "selected";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"user_topic"),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":14,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});