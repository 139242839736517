var Handlebars = require("../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu\" data-simplebar>\n    <ul role=\"menu\" class=\"popover-menu-list\">\n        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" id=\"delete_all_drafts_sidebar\" class=\"popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-trash\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Delete all drafts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":49},"end":{"line":6,"column":75}}}))
    + "</span>\n            </a>\n        </li>\n    </ul>\n</div>\n";
},"useData":true});