var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tooltip-inner-content italic\">"
    + container.escapeExpression((((helper = lookupProperty(helpers,"display_current_view") || container.strict(depth0, "display_current_view", {"start":{"line":4,"column":52},"end":{"line":4,"column":72}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"display_current_view","hash":{},"data":data,"loc":{"start":{"line":4,"column":50},"end":{"line":4,"column":74}}}) : helper)))
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Go to conversation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":9},"end":{"line":2,"column":35}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"display_current_view"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":5,"column":11}}})) != null ? stack1 : "")
    + "</div>\n"
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Ctrl",".",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":7,"column":0},"end":{"line":7,"column":35}}}))
    + "\n";
},"useData":true});