var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-step=\"1\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Zulip needs to send email to confirm users' addresses and send notifications.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":89}}}))
    + "\n    <a class=\"alert-link\" href=\"https://zulip.readthedocs.io/en/latest/production/email.html\" target=\"_blank\" rel=\"noopener noreferrer\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"See how to configure email.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":44}}}))
    + "\n    </a>\n</div>\n";
},"useData":true});