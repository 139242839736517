var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p class=\"banner_content\">"
    + container.escapeExpression((((helper = lookupProperty(helpers,"banner_text") || container.strict(depth0, "banner_text", {"start":{"line":4,"column":36},"end":{"line":4,"column":47}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"banner_text","hash":{},"data":data,"loc":{"start":{"line":4,"column":34},"end":{"line":4,"column":49}}}) : helper)))
    + "</p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"banner_content\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button class=\"main-view-banner-action-button"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"hide_close_button"),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":53},"end":{"line":9,"column":96}}})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression((((helper = lookupProperty(helpers,"button_text") || container.strict(depth0, "button_text", {"start":{"line":9,"column":100},"end":{"line":9,"column":111}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"button_text","hash":{},"data":data,"loc":{"start":{"line":9,"column":98},"end":{"line":9,"column":113}}}) : helper)))
    + "</button>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return " right_edge";
},"8":function(container,depth0,helpers,partials,data) {
    return "    <a role=\"button\" class=\"zulip-icon zulip-icon-close main-view-banner-close-button\"></a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"main-view-banner "
    + alias4((((helper = lookupProperty(helpers,"banner_type") || alias1(depth0, "banner_type", {"start":{"line":1,"column":31},"end":{"line":1,"column":42}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"banner_type","hash":{},"data":data,"loc":{"start":{"line":1,"column":29},"end":{"line":1,"column":44}}}) : helper)))
    + " "
    + alias4((((helper = lookupProperty(helpers,"classname") || alias1(depth0, "classname", {"start":{"line":1,"column":47},"end":{"line":1,"column":56}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"classname","hash":{},"data":data,"loc":{"start":{"line":1,"column":45},"end":{"line":1,"column":58}}}) : helper)))
    + "\">\n    <div class=\"main-view-banner-elements-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"banner_text"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"button_text"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":10,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias2,lookupProperty(depth0,"hide_close_button"),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":14,"column":15}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});