var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "fake_disabled_button";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <i class=\"fa fa-check-square-o\"></i>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "    <i class=\"fa fa-square-o\"></i>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./dropdown_widget.hbs"),depth0,{"name":"./dropdown_widget","hash":{"widget_name":"recent-view-filter"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<button data-filter=\"include_private\" type=\"button\" class=\"btn btn-default btn-recent-filters "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_spectator"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":94},"end":{"line":2,"column":141}}})) != null ? stack1 : "")
    + "\" role=\"checkbox\" aria-checked=\"true\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"filter_pm"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + "    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Include DMs",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":8,"column":24}}}))
    + "\n</button>\n<button data-filter=\"unread\" type=\"button\" class=\"btn btn-default btn-recent-filters "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_spectator"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":85},"end":{"line":10,"column":132}}})) != null ? stack1 : "")
    + "\" role=\"checkbox\" aria-checked=\"false\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"filter_unread"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":15,"column":11}}})) != null ? stack1 : "")
    + "    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Unread",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":16,"column":19}}}))
    + "\n</button>\n<button data-filter=\"participated\" type=\"button\" class=\"btn btn-default btn-recent-filters "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_spectator"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":91},"end":{"line":18,"column":138}}})) != null ? stack1 : "")
    + "\" role=\"checkbox\" aria-checked=\"false\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"filter_participated"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":23,"column":11}}})) != null ? stack1 : "")
    + "    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Participated",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":4},"end":{"line":24,"column":25}}}))
    + "\n</button>\n";
},"usePartial":true,"useData":true});