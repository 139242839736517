var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"user_profile_subscribe_widget\">\n    "
    + ((stack1 = container.invokePartial(require("./dropdown_widget.hbs"),depth0,{"name":"dropdown_widget","hash":{"widget_name":"user_profile_subscribe"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <div class=\"add-subscription-button-wrapper\">\n        <button type=\"button\" name=\"subscribe\" class=\"add-subscription-button button small rounded\">\n            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Subscribe",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":30}}}))
    + "\n        </button>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});