var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"message-list\" data-message-list-id=\""
    + alias2((((helper = lookupProperty(helpers,"message_list_id") || container.strict(depth0, "message_list_id", {"start":{"line":1,"column":51},"end":{"line":1,"column":66}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"message_list_id","hash":{},"data":data,"loc":{"start":{"line":1,"column":48},"end":{"line":1,"column":69}}}) : helper)))
    + "\" role=\"list\" aria-live=\"polite\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":114},"end":{"line":1,"column":131}}}))
    + "\"></div>\n";
},"useData":true});