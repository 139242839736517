var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./help_link_widget.hbs"),depth0,{"name":"help_link_widget","hash":{"link":lookupProperty(depth0,"help_link")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return "data-setting-value-type=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "value_type", {"start":{"line":5,"column":153},"end":{"line":5,"column":163}} ), depth0))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"input-group\" id=\""
    + alias3(alias2(alias1(depth0, "widget_name", {"start":{"line":1,"column":31},"end":{"line":1,"column":42}} ), depth0))
    + "_widget_container\">\n    <label class=\"settings-field-label\" for=\""
    + alias3(alias2(alias1(depth0, "widget_name", {"start":{"line":2,"column":47},"end":{"line":2,"column":58}} ), depth0))
    + "_widget\">"
    + alias3(alias2(alias1(depth0, "label", {"start":{"line":2,"column":71},"end":{"line":2,"column":76}} ), depth0))
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"help_link"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":70}}})) != null ? stack1 : "")
    + "\n    </label>\n    <span class=\"prop-element hide\" id=\"id_"
    + alias3(alias2(alias1(depth0, "widget_name", {"start":{"line":5,"column":45},"end":{"line":5,"column":56}} ), depth0))
    + "\" data-setting-widget-type=\"dropdown-list-widget\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"value_type"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":108},"end":{"line":5,"column":173}}})) != null ? stack1 : "")
    + "></span>\n    "
    + ((stack1 = container.invokePartial(require("./dropdown_widget.hbs"),depth0,{"name":"dropdown_widget","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});