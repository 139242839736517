var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr>\n    <td class=\"empty-table-message\" colspan=\""
    + alias4((((helper = lookupProperty(helpers,"column_count") || alias1(depth0, "column_count", {"start":{"line":2,"column":47},"end":{"line":2,"column":59}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"column_count","hash":{},"data":data,"loc":{"start":{"line":2,"column":45},"end":{"line":2,"column":61}}}) : helper)))
    + "\">\n        "
    + alias4((((helper = lookupProperty(helpers,"empty_list_message") || alias1(depth0, "empty_list_message", {"start":{"line":3,"column":10},"end":{"line":3,"column":28}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"empty_list_message","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":30}}}) : helper)))
    + "\n    </td>\n</tr>\n";
},"useData":true});