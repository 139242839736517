var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"new-style\" id=\"add-custom-emoji-form\">\n    <div>\n        <input type=\"file\" name=\"emoji_file_input\" class=\"notvisible\"\n          id=\"emoji_file_input\" value=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Upload image or GIF",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":39},"end":{"line":4,"column":67}}}))
    + "\"/>\n        <button type=\"button\" class=\"button white rounded\" style=\"display: none;\" id=\"emoji_image_clear_button\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Clear image",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":112},"end":{"line":5,"column":132}}}))
    + "</button>\n        <button type=\"button\" class=\"button rounded\" id=\"emoji_upload_button\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Upload image or GIF",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":78},"end":{"line":6,"column":106}}}))
    + "</button>\n        <div style=\"display: none;\" id=\"emoji_preview_text\">\n            Preview: <i id=\"emoji_placeholder_icon\" class=\"fa fa-file-image-o\" aria-hidden=\"true\"></i><img class=\"emoji\" id=\"emoji_preview_image\" src=\"\"/>\n        </div>\n        <div id=\"emoji-file-name\"></div>\n    </div>\n    <div id=\"emoji_file_input_error\" class=\"text-error\"></div>\n    <div class=\"emoji_name_input\">\n        <label for=\"emoji_name\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Emoji name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":58},"end":{"line":14,"column":77}}}))
    + "</label>\n        <input type=\"text\" name=\"name\" id=\"emoji_name\" class=\"modal_text_input\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"leafy green vegetable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":93},"end":{"line":15,"column":123}}}))
    + "\" />\n    </div>\n</form>\n";
},"useData":true});