var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " hide ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='choice-row movable-row' data-value=\""
    + alias4((((helper = lookupProperty(helpers,"value") || alias1(depth0, "value", {"start":{"line":1,"column":50},"end":{"line":1,"column":55}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":1,"column":48},"end":{"line":1,"column":57}}}) : helper)))
    + "\">\n    <i class=\"fa fa-ellipsis-v\" aria-hidden=\"true\"></i>\n    <i class=\"fa fa-ellipsis-v\" aria-hidden=\"true\"></i>\n    <input type='text' class=\"modal_text_input\" placeholder='"
    + alias4(lookupProperty(helpers,"t").call(alias2,"New option",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":61},"end":{"line":4,"column":80}}}))
    + "' value=\""
    + alias4((((helper = lookupProperty(helpers,"text") || alias1(depth0, "text", {"start":{"line":4,"column":92},"end":{"line":4,"column":96}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"text","hash":{},"data":data,"loc":{"start":{"line":4,"column":89},"end":{"line":4,"column":99}}}) : helper)))
    + "\" />\n\n    <button type='button' class=\"button rounded small delete-choice tippy-zulip-tooltip "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"new_empty_choice_row"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":88},"end":{"line":6,"column":129}}})) != null ? stack1 : "")
    + "\" data-tippy-content=\""
    + alias4(lookupProperty(helpers,"t").call(alias2,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":151},"end":{"line":6,"column":166}}}))
    + "\">\n        <i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i>\n    </button>\n</div>\n";
},"useData":true});