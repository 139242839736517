var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "user_sidebar_entry_me ";
},"3":function(container,depth0,helpers,partials,data) {
    return " user-fade ";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"user-name-and-status-wrapper\">\n                    <div class=\"user-name-and-status-emoji\">\n                        "
    + ((stack1 = container.invokePartial(require("./user_full_name.hbs"),depth0,{"name":"user_full_name","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        "
    + ((stack1 = container.invokePartial(require("./status_emoji.hbs"),lookupProperty(depth0,"status_emoji_info"),{"name":"status_emoji","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </div>\n                    <span class=\"status-text\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "status_text", {"start":{"line":14,"column":48},"end":{"line":14,"column":59}} ), depth0))
    + "</span>\n                </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"user-name-and-status-emoji\">\n                    "
    + ((stack1 = container.invokePartial(require("./user_full_name.hbs"),depth0,{"name":"user_full_name","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    "
    + ((stack1 = container.invokePartial(require("./status_emoji.hbs"),lookupProperty(depth0,"status_emoji_info"),{"name":"status_emoji","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "hide";
},"11":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(container.strict(depth0, "num_unread", {"start":{"line":23,"column":93},"end":{"line":23,"column":103}} ), depth0));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-user-id=\""
    + alias3(alias2(alias1(depth0, "user_id", {"start":{"line":1,"column":20},"end":{"line":1,"column":27}} ), depth0))
    + "\" class=\"user_sidebar_entry "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_current_user"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":57},"end":{"line":1,"column":109}}})) != null ? stack1 : "")
    + " narrow-filter "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"faded"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":124},"end":{"line":1,"column":155}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"selectable_sidebar_block\">\n        <span class=\""
    + alias3(alias2(alias1(depth0, "user_circle_class", {"start":{"line":3,"column":23},"end":{"line":3,"column":40}} ), depth0))
    + " user_circle\"></span>\n        <a class=\"user-presence-link\"\n          href=\""
    + alias3(alias2(alias1(depth0, "href", {"start":{"line":5,"column":18},"end":{"line":5,"column":22}} ), depth0))
    + "\"\n          data-user-id=\""
    + alias3(alias2(alias1(depth0, "user_id", {"start":{"line":6,"column":26},"end":{"line":6,"column":33}} ), depth0))
    + "\"\n          data-name=\""
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":7,"column":23},"end":{"line":7,"column":27}} ), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(lookupProperty(depth0,"user_list_style"),"WITH_STATUS"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":21,"column":19}}})) != null ? stack1 : "")
    + "        </a>\n        <span class=\"unread_count "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(depth0,"num_unread"),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":34},"end":{"line":23,"column":71}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"num_unread"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":73},"end":{"line":23,"column":112}}})) != null ? stack1 : "")
    + "</span>\n    </div>\n    <span class=\"sidebar-menu-icon user-list-sidebar-menu-icon\"><i class=\"zulip-icon zulip-icon-more-vertical\" aria-hidden=\"true\"></i></span>\n</li>\n";
},"usePartial":true,"useData":true});