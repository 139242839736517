var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"emoji_alt_code"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":11,"column":10}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"emoji_alt_code\">&nbsp;:"
    + container.escapeExpression((((helper = lookupProperty(helpers,"emoji_name") || container.strict(depth0, "emoji_name", {"start":{"line":3,"column":38},"end":{"line":3,"column":48}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"emoji_name","hash":{},"data":data,"loc":{"start":{"line":3,"column":36},"end":{"line":3,"column":50}}}) : helper)))
    + ":</span>";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"still_url"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":11,"column":0}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<img src=\""
    + alias4((((helper = lookupProperty(helpers,"still_url") || alias1(depth0, "still_url", {"start":{"line":5,"column":12},"end":{"line":5,"column":21}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"still_url","hash":{},"data":data,"loc":{"start":{"line":5,"column":10},"end":{"line":5,"column":23}}}) : helper)))
    + "\" class=\"emoji status-emoji status-emoji-name\" data-animated-url=\""
    + alias4((((helper = lookupProperty(helpers,"url") || alias1(depth0, "url", {"start":{"line":5,"column":91},"end":{"line":5,"column":94}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":5,"column":89},"end":{"line":5,"column":96}}}) : helper)))
    + "\" data-still-url=\""
    + alias4((((helper = lookupProperty(helpers,"still_url") || alias1(depth0, "still_url", {"start":{"line":5,"column":116},"end":{"line":5,"column":125}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"still_url","hash":{},"data":data,"loc":{"start":{"line":5,"column":114},"end":{"line":5,"column":127}}}) : helper)))
    + "\" data-tippy-content=\":"
    + alias4((((helper = lookupProperty(helpers,"emoji_name") || alias1(depth0, "emoji_name", {"start":{"line":5,"column":152},"end":{"line":5,"column":162}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"emoji_name","hash":{},"data":data,"loc":{"start":{"line":5,"column":150},"end":{"line":5,"column":164}}}) : helper)))
    + ":\" />";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"url"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":6,"column":0},"end":{"line":11,"column":0}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<img src=\""
    + alias4((((helper = lookupProperty(helpers,"url") || alias1(depth0, "url", {"start":{"line":8,"column":12},"end":{"line":8,"column":15}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":8,"column":10},"end":{"line":8,"column":17}}}) : helper)))
    + "\" class=\"emoji status-emoji status-emoji-name\" data-animated-url=\""
    + alias4((((helper = lookupProperty(helpers,"url") || alias1(depth0, "url", {"start":{"line":8,"column":85},"end":{"line":8,"column":88}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":8,"column":83},"end":{"line":8,"column":90}}}) : helper)))
    + "\" data-tippy-content=\":"
    + alias4((((helper = lookupProperty(helpers,"emoji_name") || alias1(depth0, "emoji_name", {"start":{"line":8,"column":115},"end":{"line":8,"column":125}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"emoji_name","hash":{},"data":data,"loc":{"start":{"line":8,"column":113},"end":{"line":8,"column":127}}}) : helper)))
    + ":\" />";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"emoji_name"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":0},"end":{"line":11,"column":0}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"emoji status-emoji status-emoji-name emoji-"
    + alias4((((helper = lookupProperty(helpers,"emoji_code") || alias1(depth0, "emoji_code", {"start":{"line":10,"column":58},"end":{"line":10,"column":68}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"emoji_code","hash":{},"data":data,"loc":{"start":{"line":10,"column":56},"end":{"line":10,"column":70}}}) : helper)))
    + "\" data-tippy-content=\":"
    + alias4((((helper = lookupProperty(helpers,"emoji_name") || alias1(depth0, "emoji_name", {"start":{"line":10,"column":95},"end":{"line":10,"column":105}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"emoji_name","hash":{},"data":data,"loc":{"start":{"line":10,"column":93},"end":{"line":10,"column":107}}}) : helper)))
    + ":\"></span>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":12,"column":10}}})) != null ? stack1 : "");
},"useData":true});