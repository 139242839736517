var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression((((helper = lookupProperty(helpers,"name") || container.strict(depth0, "name", {"start":{"line":4,"column":14},"end":{"line":4,"column":18}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":20}}}) : helper)))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a class=\"group_list_item_link\" href=\""
    + alias4((((helper = lookupProperty(helpers,"group_edit_url") || alias1(depth0, "group_edit_url", {"start":{"line":6,"column":52},"end":{"line":6,"column":66}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"group_edit_url","hash":{},"data":data,"loc":{"start":{"line":6,"column":50},"end":{"line":6,"column":68}}}) : helper)))
    + "\">"
    + alias4((((helper = lookupProperty(helpers,"name") || alias1(depth0, "name", {"start":{"line":6,"column":72},"end":{"line":6,"column":76}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":6,"column":70},"end":{"line":6,"column":78}}}) : helper)))
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr data-group-id=\""
    + container.escapeExpression((((helper = lookupProperty(helpers,"group_id") || container.strict(depth0, "group_id", {"start":{"line":1,"column":21},"end":{"line":1,"column":29}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"group_id","hash":{},"data":data,"loc":{"start":{"line":1,"column":19},"end":{"line":1,"column":31}}}) : helper)))
    + "\">\n    <td class=\"group_list_item\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_guest"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n</tr>\n";
},"useData":true});