var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-step=\"1\">\n    <span>\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Your profile is missing required fields.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":56}}}))
    + "&nbsp;\n        <a class=\"alert-link\" href=\"#settings/profile\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Edit your profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":55},"end":{"line":4,"column":80}}}))
    + "</a>\n    </span>\n</div>\n";
},"useData":true});