var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"stream-row\" data-stream-id=\""
    + alias3(alias2(alias1(depth0, "stream_id", {"start":{"line":3,"column":42},"end":{"line":3,"column":51}} ), depth0))
    + "\" data-stream-name=\""
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":3,"column":75},"end":{"line":3,"column":79}} ), depth0))
    + "\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"subscribed"),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":49,"column":11}}})) != null ? stack1 : "")
    + "    "
    + ((stack1 = container.invokePartial(require("./subscription_setting_icon.hbs"),depth0,{"name":"subscription_setting_icon","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <div class=\"sub-info-box\">\n        <div class=\"top-bar\">\n            <div class=\"stream-name\">"
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":53,"column":39},"end":{"line":53,"column":43}} ), depth0))
    + "</div>\n            <div class=\"subscriber-count tippy-zulip-tooltip\" data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Subscriber count",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":82},"end":{"line":54,"column":107}}}))
    + "\">\n                "
    + ((stack1 = container.invokePartial(require("./subscriber_count.hbs"),depth0,{"name":"subscriber_count","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n        <div class=\"bottom-bar\">\n            <div class=\"description rendered_markdown\" data-no-description=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"No description.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":76},"end":{"line":59,"column":99}}}))
    + "\">"
    + alias3(lookupProperty(helpers,"rendered_markdown").call(alias4,lookupProperty(depth0,"rendered_description"),{"name":"rendered_markdown","hash":{},"data":data,"loc":{"start":{"line":59,"column":101},"end":{"line":59,"column":143}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_old_stream"),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.program(19, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":60,"column":12},"end":{"line":69,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"check checked sub_unsub_button tippy-zulip-tooltip\" data-tooltip-template-id=\"unsubscribe-from-"
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":6,"column":117},"end":{"line":6,"column":121}} ), depth0))
    + "-stream-tooltip-template\">\n\n            <template id=\"unsubscribe-from-"
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":8,"column":45},"end":{"line":8,"column":49}} ), depth0))
    + "-stream-tooltip-template\">\n                <span>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":13,"column":27}}})) != null ? stack1 : "")
    + "                </span>\n            </template>\n\n            <svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" width=\"100%\" height=\"100%\" viewBox=\"0 0 512 512\" style=\"enable-background:new 0 0 512 512;\" xml:space=\"preserve\">\n                <path d=\"M448,71.9c-17.3-13.4-41.5-9.3-54.1,9.1L214,344.2l-99.1-107.3c-14.6-16.6-39.1-17.4-54.7-1.8 c-15.6,15.5-16.4,41.6-1.7,58.1c0,0,120.4,133.6,137.7,147c17.3,13.4,41.5,9.3,54.1-9.1l206.3-301.7 C469.2,110.9,465.3,85.2,448,71.9z\"/>\n            </svg>\n            <div class='sub_unsub_status'></div>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        Unsubscribe from <z-stream></z-stream>\n                        \n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("../inline_decorated_stream_name.hbs"),depth0,{"name":"../inline_decorated_stream_name","hash":{"stream":depth0},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"check sub_unsub_button "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"should_display_subscription_button"),{"name":"unless","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":43},"end":{"line":23,"column":108}}})) != null ? stack1 : "")
    + " tippy-zulip-tooltip\"\n          data-tooltip-template-id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_subscription_button"),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.program(11, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":24,"column":36},"end":{"line":24,"column":190}}})) != null ? stack1 : "")
    + "\">\n\n            <template id=\"subscribe-to-"
    + alias4(alias3(alias2(depth0, "name", {"start":{"line":26,"column":41},"end":{"line":26,"column":45}} ), depth0))
    + "-stream-tooltip-template\">\n                <span>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":20},"end":{"line":31,"column":27}}})) != null ? stack1 : "")
    + "                </span>\n            </template>\n\n            <template id=\"cannot-subscribe-to-"
    + alias4(alias3(alias2(depth0, "name", {"start":{"line":35,"column":48},"end":{"line":35,"column":52}} ), depth0))
    + "-stream-tooltip-template\">\n                <span>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":20},"end":{"line":40,"column":27}}})) != null ? stack1 : "")
    + "                </span>\n            </template>\n\n            <svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" width=\"100%\" height=\"100%\" viewBox=\"0 0 512 512\" style=\"enable-background:new 0 0 512 512;\" xml:space=\"preserve\">\n                <path d=\"M459.319,229.668c0,22.201-17.992,40.193-40.205,40.193H269.85v149.271c0,22.207-17.998,40.199-40.196,40.193   c-11.101,0-21.149-4.492-28.416-11.763c-7.276-7.281-11.774-17.324-11.769-28.419l-0.006-149.288H40.181   c-11.094,0-21.134-4.492-28.416-11.774c-7.264-7.264-11.759-17.312-11.759-28.413C0,207.471,17.992,189.475,40.202,189.475h149.267   V40.202C189.469,17.998,207.471,0,229.671,0c22.192,0.006,40.178,17.986,40.19,40.187v149.288h149.282   C441.339,189.487,459.308,207.471,459.319,229.668z\"/>\n            </svg>\n            <div class='sub_unsub_status'></div>\n        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"9":function(container,depth0,helpers,partials,data) {
    return "subscribe-to-"
    + container.escapeExpression(container.lambda(container.strict(depth0, "name", {"start":{"line":24,"column":93},"end":{"line":24,"column":97}} ), depth0))
    + "-stream-tooltip-template";
},"11":function(container,depth0,helpers,partials,data) {
    return "cannot-subscribe-to-"
    + container.escapeExpression(container.lambda(container.strict(depth0, "name", {"start":{"line":24,"column":153},"end":{"line":24,"column":157}} ), depth0))
    + "-stream-tooltip-template";
},"13":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        Subscribe to <z-stream></z-stream>\n                        \n";
},"15":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        Cannot subscribe to <z-stream></z-stream>\n                        \n";
},"17":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"stream-message-count tippy-zulip-tooltip\" data-tippy-content=\""
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Estimated messages per week",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":61,"column":86},"end":{"line":61,"column":122}}}))
    + "\">\n                <i class=\"fa fa-bar-chart\"></i>\n                <span class=\"stream-message-count-text\">"
    + alias1(container.lambda(container.strict(depth0, "stream_weekly_traffic", {"start":{"line":63,"column":58},"end":{"line":63,"column":79}} ), depth0))
    + "</span>\n            </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"stream-message-count tippy-zulip-tooltip\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Channel created recently",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":86},"end":{"line":66,"column":119}}}))
    + "\">\n                <span class=\"stream-message-count-text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"New",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":56},"end":{"line":67,"column":68}}}))
    + "</span>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":73,"column":9}}})) != null ? stack1 : "");
},"3_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-stream"],"data":data,"loc":{"start":{"line":12,"column":24},"end":{"line":12,"column":107}}}) || fn;
  return fn;
  }

,"useDecorators":true,"13_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-stream"],"data":data,"loc":{"start":{"line":30,"column":24},"end":{"line":30,"column":107}}}) || fn;
  return fn;
  }

,"15_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-stream"],"data":data,"loc":{"start":{"line":39,"column":24},"end":{"line":39,"column":107}}}) || fn;
  return fn;
  }

,"usePartial":true,"useData":true,"useDepths":true});