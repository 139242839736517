var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"new-style\">\n    <div class=\"input-group\">\n        <div class=\"integration-url-name-wrapper integration-url-parameter\">\n            "
    + ((stack1 = container.invokePartial(require("../dropdown_widget_with_label.hbs"),depth0,{"name":"../dropdown_widget_with_label","hash":{"label":lookupProperty(helpers,"t").call(alias1,"Integration",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":37}}}),"widget_name":"integration-name"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n    </div>\n    <div class=\"input-group\">\n        <div class=\"integration-url-stream-wrapper integration-url-parameter\">\n            "
    + ((stack1 = container.invokePartial(require("../dropdown_widget_with_label.hbs"),depth0,{"name":"../dropdown_widget_with_label","hash":{"label":lookupProperty(helpers,"t").call(alias1,"Where to send notifications",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":13,"column":53}}}),"widget_name":"integration-url-stream"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n    </div>\n    <div class=\"input-group control-label-disabled\">\n        <label class=\"checkbox\">\n            <input type=\"checkbox\" id=\"integration-url-override-topic\" class=\"integration-url-parameter\" disabled/>\n            <span></span>\n        </label>\n        <label class=\"inline\" for=\"integration-url-override-topic\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Send all notifications to a single topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":22,"column":60}}}))
    + "\n        </label>\n    </div>\n    <div class=\"input-group hide\">\n        <label for=\"integration-url-topic-input\" class=\"modal-label-field\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":75},"end":{"line":26,"column":88}}}))
    + "</label>\n        <input type=\"text\" id=\"integration-url-topic-input\" class=\"modal_text_input integration-url-parameter\" maxlength=\""
    + alias2(alias4(alias3(depth0, "max_topic_length", {"start":{"line":27,"column":125},"end":{"line":27,"column":141}} ), depth0))
    + "\" />\n    </div>\n    <div id=\"integration-events-parameter\" class=\"input-group hide\">\n        <label class=\"checkbox\">\n            <input type=\"checkbox\" id=\"show-integration-events\"/>\n            <span></span>\n        </label>\n        <label class=\"inline\" for=\"show-integration-events\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter events that will trigger notifications?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":12},"end":{"line":35,"column":66}}}))
    + "\n        </label>\n    </div>\n    <div class=\"input-group hide\" id=\"integrations-event-container\">\n        <label for=\"integrations-event-options\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Events to include:",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":48},"end":{"line":39,"column":74}}}))
    + "</label>\n        <div class=\"integration-all-events-buttons\">\n            <button class=\"button rounded\" id=\"add-all-integration-events\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Check all",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":75},"end":{"line":41,"column":92}}}))
    + "</button>\n            <button class=\"button rounded\" id=\"remove-all-integration-events\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Uncheck all",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":78},"end":{"line":42,"column":97}}}))
    + "</button>\n        </div>\n        <div id=\"integrations-event-options\"></div>\n    </div>\n    <hr />\n    <p class=\"integration-url-header\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"URL for your integration",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":38},"end":{"line":47,"column":70}}}))
    + "</p>\n    <div class=\"integration-url\">\n        "
    + alias2(alias4(alias3(depth0, "default_url_message", {"start":{"line":49,"column":10},"end":{"line":49,"column":29}} ), depth0))
    + "\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});