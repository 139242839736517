var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-step=\"1\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"This Zulip server is running an old version and should be upgraded.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":80}}}))
    + "\n    <span class=\"buttons\">\n        <a class=\"alert-link\" href=\"https://zulip.readthedocs.io/en/latest/overview/release-lifecycle.html#upgrade-nag\" target=\"_blank\" rel=\"noopener noreferrer\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Learn more",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":31}}}))
    + "\n        </a>\n        &bull;\n        <a class=\"alert-link dismiss-upgrade-nag\" role=\"button\" tabindex=0>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Dismiss for a week",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":75},"end":{"line":8,"column":102}}}))
    + "</a>\n    </span>\n</div>\n";
},"useData":true});