var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"emoji_alt_code\">&nbsp;:"
    + container.escapeExpression(container.lambda(container.strict(depth0, "emoji_name", {"start":{"line":3,"column":45},"end":{"line":3,"column":60}} ), depth0))
    + ":</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_realm_emoji"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":8,"column":4}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "        <img src=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "url", {"start":{"line":5,"column":20},"end":{"line":5,"column":28}} ), depth0))
    + "\" class=\"emoji\" />\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"emoji emoji-"
    + container.escapeExpression(container.lambda(container.strict(depth0, "emoji_code", {"start":{"line":7,"column":34},"end":{"line":7,"column":49}} ), depth0))
    + "\"></div>\n    ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias3(alias2(alias1(depth0, "class", {"start":{"line":1,"column":14},"end":{"line":1,"column":24}} ), depth0))
    + "\" aria-label=\""
    + alias3(alias2(alias1(depth0, "label", {"start":{"line":1,"column":42},"end":{"line":1,"column":52}} ), depth0))
    + "\" data-reaction-id=\""
    + alias3(alias2(alias1(depth0, "local_id", {"start":{"line":1,"column":76},"end":{"line":1,"column":89}} ), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"emoji_alt_code"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":8,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"message_reaction_count\">"
    + alias3(alias2(alias1(depth0, "vote_text", {"start":{"line":9,"column":42},"end":{"line":9,"column":56}} ), depth0))
    + "</div>\n</div>\n";
},"useData":true});