var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"edit-linkifier-form\">\n    <form class=\"linkifier-edit-form\">\n        <div class=\"input-group name_change_container\">\n            <label for=\"edit-linkifier-pattern\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Pattern",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":74},"end":{"line":4,"column":90}}}))
    + "</label>\n            <input type=\"text\" autocomplete=\"off\" id=\"edit-linkifier-pattern\" class=\"modal_text_input\" name=\"pattern\" placeholder=\"#(?P<id>[0-9]+)\" value=\""
    + alias2((((helper = lookupProperty(helpers,"pattern") || alias3(depth0, "pattern", {"start":{"line":5,"column":158},"end":{"line":5,"column":165}} ))),(typeof helper === alias4 ? helper.call(alias1,{"name":"pattern","hash":{},"data":data,"loc":{"start":{"line":5,"column":155},"end":{"line":5,"column":168}}}) : helper)))
    + "\" />\n            <div class=\"alert\" id=\"edit-linkifier-pattern-status\"></div>\n        </div>\n        <div class=\"input-group name_change_container\">\n            <label for=\"edit-linkifier-url-template\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"URL template",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":79},"end":{"line":9,"column":100}}}))
    + "</label>\n            <input type=\"text\" autocomplete=\"off\" id=\"edit-linkifier-url-template\" class=\"modal_text_input\" name=\"url_template\" placeholder=\"https://github.com/zulip/zulip/issues/{id}\" value=\""
    + alias2((((helper = lookupProperty(helpers,"url_template") || alias3(depth0, "url_template", {"start":{"line":10,"column":195},"end":{"line":10,"column":207}} ))),(typeof helper === alias4 ? helper.call(alias1,{"name":"url_template","hash":{},"data":data,"loc":{"start":{"line":10,"column":192},"end":{"line":10,"column":210}}}) : helper)))
    + "\" />\n            <div class=\"alert\" id=\"edit-linkifier-template-status\"></div>\n        </div>\n    </form>\n</div>\n";
},"useData":true});