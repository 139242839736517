var Handlebars = require("../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"separator\" class=\"popover-menu-separator\"></li>\n        <li role=\"none\" class=\"popover-menu-list-item\">\n            <div role=\"group\" class=\"tab-picker popover-menu-tab-group\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Topic visibility",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":84},"end":{"line":10,"column":109}}}))
    + "\">\n                <input type=\"radio\" id=\"sidebar-topic-muted-policy\" class=\"tab-option\" name=\"sidebar-topic-visibility-select\" data-visibility-policy=\""
    + alias2(alias4(alias3(lookupProperty(depth0,"all_visibility_policies"), "MUTED", {"start":{"line":11,"column":152},"end":{"line":11,"column":181}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"MUTED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":11,"column":191},"end":{"line":11,"column":243}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":185},"end":{"line":11,"column":259}}})) != null ? stack1 : "")
    + " />\n                <label role=\"menuitemradio\" class=\"tab-option-content tippy-zulip-delayed-tooltip\" for=\"sidebar-topic-muted-policy\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mute",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":144},"end":{"line":12,"column":157}}}))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mute",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":179},"end":{"line":12,"column":192}}}))
    + "\" tabindex=\"0\">\n                    <i class=\"zulip-icon zulip-icon-mute-new\" aria-hidden=\"true\"></i>\n                </label>\n                <input type=\"radio\" id=\"sidebar-topic-inherit-policy\" class=\"tab-option\" name=\"sidebar-topic-visibility-select\" data-visibility-policy=\""
    + alias2(alias4(alias3(lookupProperty(depth0,"all_visibility_policies"), "INHERIT", {"start":{"line":15,"column":154},"end":{"line":15,"column":185}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"INHERIT"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":15,"column":195},"end":{"line":15,"column":249}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":189},"end":{"line":15,"column":265}}})) != null ? stack1 : "")
    + " />\n                <label role=\"menuitemradio\" class=\"tab-option-content tippy-zulip-delayed-tooltip\" for=\"sidebar-topic-inherit-policy\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Default",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":146},"end":{"line":16,"column":162}}}))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Default",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":184},"end":{"line":16,"column":200}}}))
    + "\" tabindex=\"0\">\n                    <i class=\"zulip-icon zulip-icon-inherit\" aria-hidden=\"true\"></i>\n                </label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"stream_muted"),lookupProperty(depth0,"topic_unmuted"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":19,"column":22},"end":{"line":19,"column":53}}}),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":24,"column":23}}})) != null ? stack1 : "")
    + "                <input type=\"radio\" id=\"sidebar-topic-followed-policy\" class=\"tab-option\" name=\"sidebar-topic-visibility-select\" data-visibility-policy=\""
    + alias2(alias4(alias3(lookupProperty(depth0,"all_visibility_policies"), "FOLLOWED", {"start":{"line":25,"column":155},"end":{"line":25,"column":187}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"FOLLOWED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":25,"column":197},"end":{"line":25,"column":252}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":191},"end":{"line":25,"column":268}}})) != null ? stack1 : "")
    + " />\n                <label role=\"menuitemradio\" class=\"tab-option-content tippy-zulip-delayed-tooltip\" for=\"sidebar-topic-followed-policy\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Follow",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":147},"end":{"line":26,"column":162}}}))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Follow",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":184},"end":{"line":26,"column":199}}}))
    + "\" tabindex=\"0\">\n                    <i class=\"zulip-icon zulip-icon-follow\" aria-hidden=\"true\"></i>\n                </label>\n                <span class=\"slider\"></span>\n            </div>\n        </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "checked";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type=\"radio\" id=\"sidebar-topic-unmuted-policy\" class=\"tab-option\" name=\"sidebar-topic-visibility-select\" data-visibility-policy=\""
    + alias1(container.lambda(container.strict(lookupProperty(depth0,"all_visibility_policies"), "UNMUTED", {"start":{"line":20,"column":154},"end":{"line":20,"column":185}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(helpers,"eq").call(alias2,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"UNMUTED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":20,"column":195},"end":{"line":20,"column":249}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":189},"end":{"line":20,"column":265}}})) != null ? stack1 : "")
    + " />\n                <label role=\"menuitemradio\" class=\"tab-option-content tippy-zulip-delayed-tooltip\" for=\"sidebar-topic-unmuted-policy\" aria-label=\""
    + alias1(lookupProperty(helpers,"t").call(alias2,"Unmute",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":146},"end":{"line":21,"column":161}}}))
    + "\" data-tippy-content=\""
    + alias1(lookupProperty(helpers,"t").call(alias2,"Unmute",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":183},"end":{"line":21,"column":198}}}))
    + "\" tabindex=\"0\">\n                    <i class=\"zulip-icon zulip-icon-unmute-new\" aria-hidden=\"true\"></i>\n                </label>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n            <a role=\"menuitem\" class=\"sidebar-popover-unstar-all-in-topic popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-star\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Unstar all messages in topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":49},"end":{"line":39,"column":86}}}))
    + "</span>\n            </a>\n        </li>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n            <a role=\"menuitem\" class=\"sidebar-popover-mark-topic-read popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-mark-as-read\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mark all messages as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":49},"end":{"line":47,"column":83}}}))
    + "</span>\n            </a>\n        </li>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n            <a role=\"menuitem\" class=\"sidebar-popover-mark-topic-unread popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-mark-as-unread\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mark all messages as unread",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":49},"end":{"line":54,"column":85}}}))
    + "</span>\n            </a>\n        </li>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "        <li role=\"separator\" class=\"popover-menu-separator\"></li>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" class=\"sidebar-popover-move-topic-messages popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-move-alt\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Move topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":72,"column":49},"end":{"line":72,"column":68}}}))
    + "</span>\n            </a>\n        </li>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"can_rename_topic"),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":8},"end":{"line":82,"column":8}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" class=\"sidebar-popover-rename-topic-messages popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-rename\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Rename topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":79,"column":49},"end":{"line":79,"column":70}}}))
    + "</span>\n            </a>\n        </li>\n        ";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" class=\"sidebar-popover-toggle-resolved popover-menu-link\" tabindex=\"0\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"topic_is_resolved"),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":86,"column":16},"end":{"line":92,"column":23}}})) != null ? stack1 : "")
    + "            </a>\n        </li>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <i class=\"popover-menu-icon zulip-icon zulip-icon-check-x\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mark as unresolved",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":88,"column":49},"end":{"line":88,"column":75}}}))
    + "</span>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <i class=\"popover-menu-icon zulip-icon zulip-icon-check\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mark as resolved",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":91,"column":49},"end":{"line":91,"column":73}}}))
    + "</span>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" class=\"sidebar-popover-delete-topic-messages popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-trash\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Delete topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":100,"column":49},"end":{"line":100,"column":69}}}))
    + "</span>\n            </a>\n        </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu\" id=\"topic-actions-menu-popover\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <ul role=\"menu\" class=\"popover-menu-list\">\n        <li role=\"none\" class=\"popover-topic-header text-item popover-menu-list-item\">\n            <span class=\"popover-topic-name\">"
    + alias4((((helper = lookupProperty(helpers,"topic_name") || alias1(depth0, "topic_name", {"start":{"line":4,"column":47},"end":{"line":4,"column":57}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":4,"column":45},"end":{"line":4,"column":59}}}) : helper)))
    + "</span>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias2,lookupProperty(depth0,"is_spectator"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":32,"column":19}}})) != null ? stack1 : "")
    + "        <li role=\"separator\" class=\"popover-menu-separator\"></li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"has_starred_messages"),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":8},"end":{"line":42,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"has_unread_messages"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":43,"column":8},"end":{"line":57,"column":15}}})) != null ? stack1 : "")
    + "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" class=\"sidebar-popover-copy-link-to-topic popover-menu-link\" data-clipboard-text=\""
    + alias4((((helper = lookupProperty(helpers,"url") || alias1(depth0, "url", {"start":{"line":59,"column":116},"end":{"line":59,"column":119}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":59,"column":113},"end":{"line":59,"column":122}}}) : helper)))
    + "\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-link-alt\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias4(lookupProperty(helpers,"t").call(alias2,"Copy link to topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":61,"column":49},"end":{"line":61,"column":76}}}))
    + "</span>\n            </a>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(helpers,"or").call(alias2,lookupProperty(depth0,"can_move_topic"),lookupProperty(depth0,"can_rename_topic"),lookupProperty(depth0,"is_realm_admin"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":65,"column":14},"end":{"line":65,"column":65}}}),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":8},"end":{"line":67,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"can_move_topic"),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":68,"column":8},"end":{"line":82,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"can_rename_topic"),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":8},"end":{"line":95,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"is_realm_admin"),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":8},"end":{"line":103,"column":15}}})) != null ? stack1 : "")
    + "    </ul>\n</div>\n";
},"useData":true});