var Handlebars = require("../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "reaction";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_status_emoji_popover"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":1,"column":85},"end":{"line":1,"column":145}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "status";
},"6":function(container,depth0,helpers,partials,data) {
    return "composition";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"emoji-popover-tab-item "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(data,"first"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":52},"end":{"line":8,"column":81}}})) != null ? stack1 : "")
    + "\" data-tab-name='"
    + alias4((((helper = lookupProperty(helpers,"name") || alias2(depth0, "name", {"start":{"line":8,"column":100},"end":{"line":8,"column":104}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":8,"column":98},"end":{"line":8,"column":106}}}) : helper)))
    + "' title='"
    + alias4((((helper = lookupProperty(helpers,"name") || alias2(depth0, "name", {"start":{"line":8,"column":117},"end":{"line":8,"column":121}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":8,"column":115},"end":{"line":8,"column":123}}}) : helper)))
    + "'><i class=\"fa "
    + alias4((((helper = lookupProperty(helpers,"icon") || alias2(depth0, "icon", {"start":{"line":8,"column":140},"end":{"line":8,"column":144}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"icon","hash":{},"data":data,"loc":{"start":{"line":8,"column":138},"end":{"line":8,"column":146}}}) : helper)))
    + "\"></i></span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return " active ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"emoji-picker-popover\" data-emoji-destination=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"message_id"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":58},"end":{"line":1,"column":152}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"emoji-popover\">\n        <div class=\"popover-filter-input-wrapper\">\n            <input id=\"emoji-popover-filter\" class=\"popover-filter-input filter_text_input\" type=\"text\" autocomplete=\"off\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":136},"end":{"line":4,"column":151}}}))
    + "\" autofocus/>\n        </div>\n        <div class=\"emoji-popover-category-tabs\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"emoji_categories"),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":9,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"emoji-popover-emoji-map\" data-simplebar data-simplebar-tab-index=\"-1\" data-simplebar-auto-hide=\"false\" data-message-id=\""
    + alias2((((helper = lookupProperty(helpers,"message_id") || alias3(depth0, "message_id", {"start":{"line":11,"column":142},"end":{"line":11,"column":152}} ))),(typeof helper === alias4 ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":11,"column":140},"end":{"line":11,"column":154}}}) : helper)))
    + "\">\n        </div>\n        <div class=\"emoji-search-results-container\" data-simplebar data-simplebar-tab-index=\"-1\" data-simplebar-auto-hide=\"false\" data-message-id=\""
    + alias2((((helper = lookupProperty(helpers,"message_id") || alias3(depth0, "message_id", {"start":{"line":13,"column":149},"end":{"line":13,"column":159}} ))),(typeof helper === alias4 ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":13,"column":147},"end":{"line":13,"column":161}}}) : helper)))
    + "\">\n            <div class=\"emoji-popover-results-heading\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search results",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":55},"end":{"line":14,"column":78}}}))
    + "</div>\n            <div class=\"emoji-search-results\"></div>\n        </div>\n    </div>\n    <div class=\"emoji-showcase-container\"></div>\n</div>\n";
},"useData":true});