var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"todo-widget\">\n    <div class=\"todo-widget-header-area\">\n        <h4 class=\"todo-task-list-title-header\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Task list",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":48},"end":{"line":3,"column":66}}}))
    + "</h4>\n        <i class=\"fa fa-pencil todo-edit-task-list-title\"></i>\n        <div class=\"todo-task-list-title-bar\">\n            <input type=\"text\" class=\"todo-task-list-title\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add todo task list title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":73},"end":{"line":6,"column":105}}}))
    + "\" />\n            <button class=\"todo-task-list-title-remove\"><i class=\"fa fa-remove\"></i></button>\n            <button class=\"todo-task-list-title-check\"><i class=\"fa fa-check\"></i></button>\n        </div>\n    </div>\n    <ul class=\"todo-widget new-style\">\n    </ul>\n    <div class=\"add-task-bar\">\n        <input type=\"text\" class=\"add-task\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"New task",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":57},"end":{"line":14,"column":73}}}))
    + "\" />\n        <input type=\"text\" class=\"add-desc\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":57},"end":{"line":15,"column":76}}}))
    + "\" />\n        <button class=\"add-task\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add task",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":33},"end":{"line":16,"column":50}}}))
    + "</button>\n        <div class=\"widget-error\"></div>\n    </div>\n</div>\n";
},"useData":true});