var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <strong>\n            "
    + ((stack1 = container.invokePartial(require("../inline_decorated_stream_name.hbs"),depth0,{"name":"../inline_decorated_stream_name","hash":{"stream":lookupProperty(depth0,"new_stream_announcements_stream_sub")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </strong>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<label class=\"checkbox\">\n    <input type=\"checkbox\" name=\"announce\" value=\"announce\" checked />\n    <span></span>\n    "
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Announce new channel in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":35}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"new_stream_announcements_stream_sub"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + "    "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/configure-automated-notices#new-channel-announcements"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</label>\n";
},"usePartial":true,"useData":true});