var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"emoji_row\" id=\"emoji_"
    + alias4((((helper = lookupProperty(helpers,"name") || alias1(depth0, "name", {"start":{"line":2,"column":34},"end":{"line":2,"column":38}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":2,"column":32},"end":{"line":2,"column":40}}}) : helper)))
    + "\">\n    <td>\n        <span class=\"emoji_name\">"
    + alias4((((helper = lookupProperty(helpers,"display_name") || alias1(depth0, "display_name", {"start":{"line":4,"column":35},"end":{"line":4,"column":47}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"display_name","hash":{},"data":data,"loc":{"start":{"line":4,"column":33},"end":{"line":4,"column":49}}}) : helper)))
    + "</span>\n    </td>\n    <td>\n        <span class=\"emoji_image\">\n            <a href=\""
    + alias4((((helper = lookupProperty(helpers,"source_url") || alias1(depth0, "source_url", {"start":{"line":8,"column":23},"end":{"line":8,"column":33}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"source_url","hash":{},"data":data,"loc":{"start":{"line":8,"column":21},"end":{"line":8,"column":35}}}) : helper)))
    + "\" target=\"_blank\" rel=\"noopener noreferrer\">\n                <img class=\"emoji\" src=\""
    + alias4((((helper = lookupProperty(helpers,"source_url") || alias1(depth0, "source_url", {"start":{"line":9,"column":42},"end":{"line":9,"column":52}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"source_url","hash":{},"data":data,"loc":{"start":{"line":9,"column":40},"end":{"line":9,"column":54}}}) : helper)))
    + "\" alt=\""
    + alias4((((helper = lookupProperty(helpers,"display_name") || alias1(depth0, "display_name", {"start":{"line":9,"column":63},"end":{"line":9,"column":75}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"display_name","hash":{},"data":data,"loc":{"start":{"line":9,"column":61},"end":{"line":9,"column":77}}}) : helper)))
    + "\" />\n            </a>\n        </span>\n    </td>\n    <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"author"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":18,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n    <td>\n        <button class=\"button rounded small delete btn-danger tippy-zulip-delayed-tooltip\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias2,lookupProperty(depth0,"can_delete_emoji"),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":91},"end":{"line":21,"column":149}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias4(lookupProperty(helpers,"t").call(alias2,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":170},"end":{"line":21,"column":185}}}))
    + "\" data-emoji-name=\""
    + alias4((((helper = lookupProperty(helpers,"name") || alias1(depth0, "name", {"start":{"line":21,"column":206},"end":{"line":21,"column":210}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":21,"column":204},"end":{"line":21,"column":212}}}) : helper)))
    + "\">\n            <i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i>\n        </button>\n    </td>\n</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"emoji_author\">"
    + container.escapeExpression(container.lambda(container.strict(lookupProperty(depth0,"author"), "full_name", {"start":{"line":15,"column":37},"end":{"line":15,"column":53}} ), depth0))
    + "</span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"emoji_author\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Unknown author",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":35},"end":{"line":17,"column":58}}}))
    + "</span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"emoji"),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":26,"column":9}}})) != null ? stack1 : "");
},"useData":true});