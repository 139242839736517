var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<strong>\n    <span class=\"stream\">"
    + alias4((((helper = lookupProperty(helpers,"stream_name") || alias1(depth0, "stream_name", {"start":{"line":2,"column":27},"end":{"line":2,"column":38}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"stream_name","hash":{},"data":data,"loc":{"start":{"line":2,"column":25},"end":{"line":2,"column":40}}}) : helper)))
    + "</span> &gt; <span class=\"topic white-space-preserve-wrap\">"
    + alias4((((helper = lookupProperty(helpers,"topic") || alias1(depth0, "topic", {"start":{"line":2,"column":101},"end":{"line":2,"column":106}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"topic","hash":{},"data":data,"loc":{"start":{"line":2,"column":99},"end":{"line":2,"column":108}}}) : helper)))
    + "</span>\n</strong>\n";
},"useData":true});