var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"mark_as_read_turned_off_banner\" class=\"main-view-banner home-error-bar info\">\n    <p id=\"mark_as_read_turned_off_content\" class=\"banner_content\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"To preserve your reading state, this view does not mark messages as read.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":90}}}))
    + "\n    </p>\n    <button id=\"mark_view_read\" class=\"main-view-banner-action-button\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mark as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":29}}}))
    + "\n    </button>\n    <a role=\"button\" id=\"mark_as_read_close\" class=\"zulip-icon zulip-icon-close main-view-banner-close-button\"></a>\n</div>\n\n";
},"useData":true});