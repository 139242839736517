var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"remove_subscription\">\n        <div class=\"subscription_list_remove\">\n            <button type=\"button\" name=\"unsubscribe\" class=\"remove-subscription-button button small rounded btn-danger "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"show_private_stream_unsub_tooltip"),lookupProperty(depth0,"show_last_user_in_private_stream_unsub_tooltip"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":11,"column":125},"end":{"line":11,"column":210}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":119},"end":{"line":11,"column":238}}})) != null ? stack1 : "")
    + "\" data-tippy-content='"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"show_private_stream_unsub_tooltip"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":11,"column":260},"end":{"line":11,"column":463}}})) != null ? stack1 : "")
    + "'>\n                "
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Unsubscribe",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":36}}}))
    + "\n            </button>\n        </div>\n    </td>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "tippy-zulip-tooltip";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Use channel settings to unsubscribe from private channels.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":301},"end":{"line":11,"column":367}}}));
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Use channel settings to unsubscribe the last user from a private channel.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":375},"end":{"line":11,"column":456}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr data-stream-id=\""
    + alias3(alias2(alias1(depth0, "stream_id", {"start":{"line":1,"column":22},"end":{"line":1,"column":31}} ), depth0))
    + "\">\n    <td class=\"subscription_list_stream\">\n        <span class=\"stream-privacy-original-color-"
    + alias3(alias2(alias1(depth0, "stream_id", {"start":{"line":3,"column":53},"end":{"line":3,"column":62}} ), depth0))
    + " stream-privacy filter-icon\" style=\"color: "
    + alias3(alias2(alias1(depth0, "stream_color", {"start":{"line":3,"column":109},"end":{"line":3,"column":121}} ), depth0))
    + "\">\n            "
    + ((stack1 = container.invokePartial(require("./stream_privacy.hbs"),depth0,{"name":"stream_privacy","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </span>\n        <a class = \"stream_list_item\" href=\""
    + alias3(alias2(alias1(depth0, "stream_edit_url", {"start":{"line":6,"column":46},"end":{"line":6,"column":61}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":6,"column":67},"end":{"line":6,"column":71}} ), depth0))
    + "</a>\n    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"show_unsubscribe_button"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":16,"column":11}}})) != null ? stack1 : "")
    + "</tr>\n";
},"usePartial":true,"useData":true});