var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((((helper = lookupProperty(helpers,"custom_class") || container.strict(depth0, "custom_class", {"start":{"line":1,"column":83},"end":{"line":1,"column":95}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"custom_class","hash":{},"data":data,"loc":{"start":{"line":1,"column":81},"end":{"line":1,"column":97}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-process=\""
    + alias4((((helper = lookupProperty(helpers,"data_process") || alias1(depth0, "data_process", {"start":{"line":1,"column":21},"end":{"line":1,"column":33}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"data_process","hash":{},"data":data,"loc":{"start":{"line":1,"column":19},"end":{"line":1,"column":35}}}) : helper)))
    + "\" class=\"alert alert-info "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"custom_class"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":61},"end":{"line":1,"column":104}}})) != null ? stack1 : "")
    + "\">\n    "
    + ((stack1 = (((helper = lookupProperty(helpers,"rendered_alert_content_html") || alias1(depth0, "rendered_alert_content_html", {"start":{"line":2,"column":7},"end":{"line":2,"column":34}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"rendered_alert_content_html","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":37}}}) : helper))) != null ? stack1 : "")
    + "\n    <span class=\"close\" data-dismiss=\"alert\" aria-label=\""
    + alias4(lookupProperty(helpers,"t").call(alias2,"Close",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":57},"end":{"line":3,"column":71}}}))
    + "\" role=\"button\" tabindex=0>&times;</span>\n</div>\n\n";
},"useData":true});