var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"empty-list-message\">"
    + container.escapeExpression((((helper = lookupProperty(helpers,"empty_list_message") || container.strict(depth0, "empty_list_message", {"start":{"line":1,"column":33},"end":{"line":1,"column":51}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"empty_list_message","hash":{},"data":data,"loc":{"start":{"line":1,"column":31},"end":{"line":1,"column":53}}}) : helper)))
    + "</li>\n";
},"useData":true});