var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " guest-avatar";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=container.strict, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"u-"
    + alias2(container.lambda(alias1(lookupProperty(depth0,"msg"), "sender_id", {"start":{"line":1,"column":16},"end":{"line":1,"column":29}} ), depth0))
    + " message-avatar sender_info_hover view_user_card_tooltip no-select\" aria-hidden=\"true\" data-is-bot=\""
    + alias2((((helper = lookupProperty(helpers,"sender_is_bot") || alias1(depth0, "sender_is_bot", {"start":{"line":1,"column":133},"end":{"line":1,"column":146}} ))),(typeof helper === alias4 ? helper.call(alias3,{"name":"sender_is_bot","hash":{},"data":data,"loc":{"start":{"line":1,"column":131},"end":{"line":1,"column":148}}}) : helper)))
    + "\">\n    <div class=\"inline_profile_picture ";
  stack1 = (((helper = lookupProperty(helpers,"sender_is_guest") || alias1(depth0, "sender_is_guest", {"start":{"line":2,"column":42},"end":{"line":2,"column":57}} ))),(options={"name":"sender_is_guest","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":39},"end":{"line":2,"column":92}}}),(typeof helper === alias4 ? helper.call(alias3,options) : helper));
  if (!lookupProperty(helpers,"sender_is_guest")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n        <img src=\""
    + alias2((((helper = lookupProperty(helpers,"small_avatar_url") || alias1(depth0, "small_avatar_url", {"start":{"line":3,"column":20},"end":{"line":3,"column":36}} ))),(typeof helper === alias4 ? helper.call(alias3,{"name":"small_avatar_url","hash":{},"data":data,"loc":{"start":{"line":3,"column":18},"end":{"line":3,"column":38}}}) : helper)))
    + "\" alt=\"\" class=\"no-drag\"/>\n    </div>\n</div>";
},"useData":true});