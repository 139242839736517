var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "checked";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type=\"radio\" id=\"select-unmuted-policy\" class=\"tab-option\" name=\"visibility-policy-select\" data-visibility-policy=\""
    + alias1(container.lambda(container.strict(lookupProperty(depth0,"all_visibility_policies"), "UNMUTED", {"start":{"line":16,"column":140},"end":{"line":16,"column":171}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(helpers,"eq").call(alias2,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"UNMUTED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":16,"column":181},"end":{"line":16,"column":235}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":175},"end":{"line":16,"column":251}}})) != null ? stack1 : "")
    + " />\n                <label role=\"menuitemradio\" class=\"tab-option-content\" for=\"select-unmuted-policy\" tabindex=\"0\">\n                    <i class=\"zulip-icon zulip-icon-unmute-new\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Unmute",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":53},"end":{"line":19,"column":67}}}))
    + "</span>\n                </label>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu visibility-policy-popover\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <ul role=\"menu\" class=\"popover-menu-list\">\n        <li role=\"none\" class=\"popover-menu-list-item\">\n            <div role=\"group\" class=\"recipient-bar-topic-visibility-switcher tab-picker tab-picker-vertical\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Topic visibility",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":121},"end":{"line":4,"column":146}}}))
    + "\">\n                <input type=\"radio\" id=\"select-muted-policy\" class=\"tab-option\" name=\"visibility-policy-select\" data-visibility-policy=\""
    + alias2(alias4(alias3(lookupProperty(depth0,"all_visibility_policies"), "MUTED", {"start":{"line":5,"column":138},"end":{"line":5,"column":167}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"MUTED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":5,"column":177},"end":{"line":5,"column":229}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":171},"end":{"line":5,"column":245}}})) != null ? stack1 : "")
    + " />\n                <label role=\"menuitemradio\" class=\"tab-option-content\" for=\"select-muted-policy\" tabindex=\"0\">\n                    <i class=\"zulip-icon zulip-icon-mute-new\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mute",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":53},"end":{"line":8,"column":65}}}))
    + "</span>\n                </label>\n                <input type=\"radio\" id=\"select-inherit-policy\" class=\"tab-option\" name=\"visibility-policy-select\" data-visibility-policy=\""
    + alias2(alias4(alias3(lookupProperty(depth0,"all_visibility_policies"), "INHERIT", {"start":{"line":10,"column":140},"end":{"line":10,"column":171}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"INHERIT"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":10,"column":181},"end":{"line":10,"column":235}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":175},"end":{"line":10,"column":251}}})) != null ? stack1 : "")
    + " />\n                <label role=\"menuitemradio\" class=\"tab-option-content\" for=\"select-inherit-policy\" tabindex=\"0\">\n                    <i class=\"zulip-icon zulip-icon-inherit\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Default",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":53},"end":{"line":13,"column":68}}}))
    + "</span>\n                </label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"stream_muted"),lookupProperty(depth0,"topic_unmuted"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":15,"column":22},"end":{"line":15,"column":53}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":21,"column":23}}})) != null ? stack1 : "")
    + "                <input type=\"radio\" id=\"select-followed-policy\" class=\"tab-option\" name=\"visibility-policy-select\" data-visibility-policy=\""
    + alias2(alias4(alias3(lookupProperty(depth0,"all_visibility_policies"), "FOLLOWED", {"start":{"line":22,"column":141},"end":{"line":22,"column":173}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"FOLLOWED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":22,"column":183},"end":{"line":22,"column":238}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":177},"end":{"line":22,"column":254}}})) != null ? stack1 : "")
    + " />\n                <label role=\"menuitemradio\" class=\"tab-option-content\" for=\"select-followed-policy\" tabindex=\"0\">\n                    <i class=\"zulip-icon zulip-icon-follow\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Follow",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":53},"end":{"line":25,"column":67}}}))
    + "</span>\n                </label>\n                <span class=\"slider\"></span>\n            </div>\n        </li>\n    </ul>\n</div>\n";
},"useData":true});