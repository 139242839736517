var Handlebars = require("../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " guest-avatar";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                <div class=\"popover-menu-user-presence user_circle "
    + alias3(alias2(alias1(depth0, "user_circle_class", {"start":{"line":6,"column":69},"end":{"line":6,"column":86}} ), depth0))
    + " hidden-for-spectators\" data-presence-indicator-user-id=\""
    + alias3(alias2(alias1(depth0, "user_id", {"start":{"line":6,"column":147},"end":{"line":6,"column":154}} ), depth0))
    + "\"></div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                    <i class=\"zulip-icon zulip-icon-bot\" aria-hidden=\"true\"></i>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"bot_owner"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":18,"column":20},"end":{"line":28,"column":27}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"bot_owner\" data-tippy-content=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"bot_owner"), "full_name", {"start":{"line":19,"column":70},"end":{"line":19,"column":89}} ), depth0))
    + "\">"
    + alias3(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Owner",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":94},"end":{"line":19,"column":108}}}))
    + ":\n                            <span class=\"bot-owner-name view_bot_owner_user_profile\" data-user-id='"
    + alias3(alias2(alias1(lookupProperty(depth0,"bot_owner"), "user_id", {"start":{"line":20,"column":102},"end":{"line":20,"column":119}} ), depth0))
    + "'>\n                                "
    + alias3(alias2(alias1(lookupProperty(depth0,"bot_owner"), "full_name", {"start":{"line":21,"column":34},"end":{"line":21,"column":53}} ), depth0))
    + "\n                            </span>\n                        </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_system_bot"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":24,"column":20},"end":{"line":28,"column":20}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"System bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":29},"end":{"line":25,"column":48}}}))
    + "</div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":29},"end":{"line":27,"column":41}}}))
    + "</div>\n                    ";
},"15":function(container,depth0,helpers,partials,data) {
    return "                    <div>"
    + container.escapeExpression(container.lambda(container.strict(depth0, "user_type", {"start":{"line":30,"column":28},"end":{"line":30,"column":37}} ), depth0))
    + "</div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"text-item popover-menu-list-item hidden-for-spectators\">\n                <span class=\"personal-menu-status-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"status_emoji_info"),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":20},"end":{"line":47,"column":27}}})) != null ? stack1 : "")
    + "                    <span class=\"status_text personal-menu-status-text\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"show_placeholder_for_status_text"),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":49,"column":24},"end":{"line":53,"column":31}}})) != null ? stack1 : "")
    + "                    </span>\n                </span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_me"),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":16},"end":{"line":60,"column":23}}})) != null ? stack1 : "")
    + "            </li>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(lookupProperty(depth0,"status_emoji_info"),"emoji_alt_code"),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":40,"column":24},"end":{"line":46,"column":31}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"emoji_alt_code\">&nbsp;:"
    + container.escapeExpression(container.lambda(container.strict(lookupProperty(depth0,"status_emoji_info"), "emoji_name", {"start":{"line":41,"column":66},"end":{"line":41,"column":94}} ), depth0))
    + ":</span>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(lookupProperty(depth0,"status_emoji_info"),"url"),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(24, data, 0),"data":data,"loc":{"start":{"line":42,"column":24},"end":{"line":46,"column":24}}})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <img src=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"status_emoji_info"), "url", {"start":{"line":43,"column":40},"end":{"line":43,"column":61}} ), depth0))
    + "\" class=\"emoji status_emoji\" data-tippy-content=\":"
    + alias3(alias2(alias1(lookupProperty(depth0,"status_emoji_info"), "emoji_name", {"start":{"line":43,"column":115},"end":{"line":43,"column":143}} ), depth0))
    + ":\"/>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"emoji status_emoji emoji-"
    + alias3(alias2(alias1(lookupProperty(depth0,"status_emoji_info"), "emoji_code", {"start":{"line":45,"column":68},"end":{"line":45,"column":96}} ), depth0))
    + "\" data-tippy-content=\":"
    + alias3(alias2(alias1(lookupProperty(depth0,"status_emoji_info"), "emoji_name", {"start":{"line":45,"column":123},"end":{"line":45,"column":151}} ), depth0))
    + ":\"></span>\n                        ";
},"26":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <i class=\"personal-menu-no-status-text\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"No status text",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":68},"end":{"line":50,"column":90}}}))
    + "</i>\n";
},"28":function(container,depth0,helpers,partials,data) {
    return "                            "
    + container.escapeExpression(container.lambda(container.strict(depth0, "status_text", {"start":{"line":52,"column":30},"end":{"line":52,"column":41}} ), depth0))
    + "\n";
},"30":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <a role=\"menuitem\" tabindex=\"0\" class=\"personal-menu-clear-status user-card-clear-status-button popover-menu-link\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Clear status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":57,"column":147},"end":{"line":57,"column":167}}}))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Clear your status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":57,"column":189},"end":{"line":57,"column":215}}}))
    + "\">\n                        <i class=\"personal-menu-clear-status-icon popover-menu-icon zulip-icon zulip-icon-x-circle\" aria-hidden=\"true\"></i>\n                    </a>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"status_content_available"),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.program(35, data, 0),"data":data,"loc":{"start":{"line":64,"column":12},"end":{"line":78,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"invisible_mode"),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.program(39, data, 0),"data":data,"loc":{"start":{"line":79,"column":12},"end":{"line":93,"column":19}}})) != null ? stack1 : "");
},"33":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n                    <a role=\"menuitem\" tabindex=\"0\" class=\"update_status_text popover-menu-link\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-smile-smaller\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Edit status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":68,"column":57},"end":{"line":68,"column":77}}}))
    + "</span>\n                    </a>\n                </li>\n";
},"35":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n                    <a role=\"menuitem\" tabindex=\"0\" class=\"update_status_text popover-menu-link\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-smile-smaller\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Set status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":75,"column":57},"end":{"line":75,"column":76}}}))
    + "</span>\n                    </a>\n                </li>\n";
},"37":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li role=\"none\" class=\"link-item popover-menu-list-item\">\n                    <a role=\"menuitem\" tabindex=\"0\" class=\"invisible_mode_turn_off popover-menu-link\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-play-circle\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Turn off invisible mode",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":83,"column":57},"end":{"line":83,"column":89}}}))
    + "</span>\n                    </a>\n                </li>\n";
},"39":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n                    <a role=\"menuitem\" tabindex=\"0\" class=\"invisible_mode_turn_on popover-menu-link\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-stop-circle\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Go invisible",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":90,"column":57},"end":{"line":90,"column":78}}}))
    + "</span>\n                    </a>\n                </li>\n";
},"41":function(container,depth0,helpers,partials,data) {
    return "            <li role=\"separator\" class=\"popover-menu-separator hidden-for-spectators\"></li>\n";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"unless","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":12},"end":{"line":104,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"user_time"),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":105,"column":12},"end":{"line":110,"column":19}}})) != null ? stack1 : "");
},"44":function(container,depth0,helpers,partials,data) {
    return "                <li role=\"none\" class=\"popover-menu-list-item text-item hidden-for-spectators\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-past-time\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "user_last_seen_time_status", {"start":{"line":102,"column":55},"end":{"line":102,"column":81}} ), depth0))
    + "</span>\n                </li>\n";
},"46":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li role=\"none\" class=\"popover-menu-list-item text-item hidden-for-spectators\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-clock\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"{user_time} local time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":108,"column":53},"end":{"line":108,"column":84}}}))
    + "</span>\n                </li>\n";
},"48":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"popover-menu-list-item text-item half-opacity italic hidden-for-spectators\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.program(51, data, 0),"data":data,"loc":{"start":{"line":113,"column":16},"end":{"line":117,"column":23}}})) != null ? stack1 : "")
    + "            </li>\n";
},"49":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"This bot has been deactivated.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":114,"column":53},"end":{"line":114,"column":92}}}))
    + "</span>\n";
},"51":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"This user has been deactivated.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":116,"column":53},"end":{"line":116,"column":93}}}))
    + "</span>\n";
},"53":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"popover-menu-list-item text-item\">\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Joined {date_joined}",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":122,"column":49},"end":{"line":122,"column":78}}}))
    + "</span>\n            </li>\n";
},"55":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"user_email"),{"name":"if","hash":{},"fn":container.program(56, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":127,"column":12},"end":{"line":134,"column":19}}})) != null ? stack1 : "");
},"56":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li role=\"none\" class=\"popover-menu-list-item text-item user-card-popover-email-field hidden-for-spectators\">\n                    <span class=\"user_popover_email\">"
    + alias3(alias2(alias1(depth0, "user_email", {"start":{"line":129,"column":56},"end":{"line":129,"column":66}} ), depth0))
    + "</span>\n                    <a role=\"menuitem\" tabindex=\"0\" id=\"popover-menu-copy-email\" class=\"popover-menu-link hide_copy_icon\" aria-label=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Copy email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":130,"column":134},"end":{"line":130,"column":152}}}))
    + "\" data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Copy email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":130,"column":174},"end":{"line":130,"column":193}}}))
    + "\" data-clipboard-text=\""
    + alias3(alias2(alias1(depth0, "user_email", {"start":{"line":130,"column":219},"end":{"line":130,"column":229}} ), depth0))
    + "\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-copy hide\" aria-hidden=\"true\"></i>\n                    </a>\n                </li>\n";
},"58":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"View your profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":141,"column":53},"end":{"line":141,"column":79}}}))
    + "</span>\n";
},"60":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"View profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":143,"column":53},"end":{"line":143,"column":74}}}))
    + "</span>\n";
},"62":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"popover-menu-list-item link-item hidden-for-spectators\">\n                <a role=\"menuitem\" class=\"popover-menu-link "
    + alias1(container.lambda(container.strict(depth0, "private_message_class", {"start":{"line":149,"column":63},"end":{"line":149,"column":84}} ), depth0))
    + "\" tabindex=\"0\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-send-dm\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Send direct message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":151,"column":53},"end":{"line":151,"column":81}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"is_sender_popover"),{"name":"if","hash":{},"fn":container.program(63, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":152,"column":20},"end":{"line":154,"column":27}}})) != null ? stack1 : "")
    + "                </a>\n            </li>\n";
},"63":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"popover_hotkey_hints").call(depth0 != null ? depth0 : (container.nullContext || {}),"R",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":153,"column":24},"end":{"line":153,"column":52}}}))
    + "\n";
},"65":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"popover-menu-list-item link-item hidden-for-spectators\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"has_message_context"),{"name":"if","hash":{},"fn":container.program(66, data, 0),"inverse":container.program(73, data, 0),"data":data,"loc":{"start":{"line":160,"column":16},"end":{"line":180,"column":23}}})) != null ? stack1 : "")
    + "            </li>\n";
},"66":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <a role=\"menuitem\" class=\"popover-menu-link mention_user\" tabindex=\"0\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-at-sign\" aria-hidden=\"true\"></i>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(67, data, 0),"inverse":container.program(69, data, 0),"data":data,"loc":{"start":{"line":163,"column":24},"end":{"line":167,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_sender_popover"),{"name":"if","hash":{},"fn":container.program(71, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":168,"column":24},"end":{"line":170,"column":31}}})) != null ? stack1 : "")
    + "                    </a>\n";
},"67":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Reply mentioning bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":164,"column":61},"end":{"line":164,"column":90}}}))
    + "</span>\n";
},"69":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Reply mentioning user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":166,"column":61},"end":{"line":166,"column":91}}}))
    + "</span>\n";
},"71":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression(lookupProperty(helpers,"popover_hotkey_hints").call(depth0 != null ? depth0 : (container.nullContext || {}),"@",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":169,"column":28},"end":{"line":169,"column":56}}}))
    + "\n";
},"73":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <a role=\"menuitem\" class=\"popover-menu-link copy_mention_syntax\" tabindex=\"0\" data-clipboard-text=\""
    + alias1(container.lambda(container.strict(depth0, "user_mention_syntax", {"start":{"line":173,"column":122},"end":{"line":173,"column":141}} ), depth0))
    + "\">\n                        <i class=\"popover-menu-icon fa zulip-icon zulip-icon-at-sign\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Copy mention syntax",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":175,"column":57},"end":{"line":175,"column":85}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"is_sender_popover"),{"name":"if","hash":{},"fn":container.program(71, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":176,"column":24},"end":{"line":178,"column":31}}})) != null ? stack1 : "")
    + "                    </a>\n";
},"75":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"popover-menu-list-item link-item hidden-for-spectators\">\n                <a role=\"menuitem\" href=\"/#settings/profile\" class=\"popover-menu-link\" tabindex=\"0\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-tool\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Edit your profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":187,"column":53},"end":{"line":187,"column":79}}}))
    + "</span>\n                </a>\n            </li>\n";
},"77":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"View messages with yourself",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":196,"column":53},"end":{"line":196,"column":89}}}))
    + "</span>\n";
},"79":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"View direct messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":198,"column":53},"end":{"line":198,"column":82}}}))
    + "</span>\n";
},"81":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"separator\" class=\"popover-menu-separator hidden-for-spectators\"></li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_mute"),{"name":"if","hash":{},"fn":container.program(82, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":210,"column":12},"end":{"line":221,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_unmute"),{"name":"if","hash":{},"fn":container.program(87, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":222,"column":12},"end":{"line":233,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_manage_user"),{"name":"if","hash":{},"fn":container.program(92, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":234,"column":12},"end":{"line":259,"column":19}}})) != null ? stack1 : "");
},"82":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li role=\"none\" class=\"popover-menu-list-item link-item\">\n                    <a role=\"menuitem\" class=\"sidebar-popover-mute-user popover-menu-link hidden-for-spectators\" tabindex=\"0\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-hide\" aria-hidden=\"true\"></i>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(83, data, 0),"inverse":container.program(85, data, 0),"data":data,"loc":{"start":{"line":214,"column":24},"end":{"line":218,"column":31}}})) != null ? stack1 : "")
    + "                    </a>\n                </li>\n";
},"83":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mute this bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":215,"column":61},"end":{"line":215,"column":83}}}))
    + "</span>\n";
},"85":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mute this user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":217,"column":61},"end":{"line":217,"column":84}}}))
    + "</span>\n";
},"87":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li role=\"none\" class=\"popover-menu-list-item link-item\">\n                    <a role=\"menuitem\" class=\"sidebar-popover-unmute-user popover-menu-link hidden-for-spectators\" tabindex=\"0\">\n                        <i class=\"popover-menu-icon fa fa-eye\" aria-hidden=\"true\"></i>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(88, data, 0),"inverse":container.program(90, data, 0),"data":data,"loc":{"start":{"line":226,"column":24},"end":{"line":230,"column":31}}})) != null ? stack1 : "")
    + "                    </a>\n                </li>\n";
},"88":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Unmute this bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":227,"column":61},"end":{"line":227,"column":85}}}))
    + "</span>\n";
},"90":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Unmute this user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":229,"column":61},"end":{"line":229,"column":86}}}))
    + "</span>\n";
},"92":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li role=\"separator\" class=\"popover-menu-separator hidden-for-spectators\"></li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_active"),{"name":"if","hash":{},"fn":container.program(93, data, 0),"inverse":container.program(98, data, 0),"data":data,"loc":{"start":{"line":236,"column":16},"end":{"line":258,"column":23}}})) != null ? stack1 : "");
},"93":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li role=\"none\" class=\"popover-menu-list-item link-item\">\n                        <a role=\"menuitem\" class=\"sidebar-popover-manage-user popover-menu-link hidden-for-spectators\" tabindex=\"0\">\n                            <i class=\"popover-menu-icon zulip-icon zulip-icon-user-cog\" aria-hidden=\"true\"></i>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(94, data, 0),"inverse":container.program(96, data, 0),"data":data,"loc":{"start":{"line":240,"column":28},"end":{"line":244,"column":35}}})) != null ? stack1 : "")
    + "                        </a>\n                    </li>\n";
},"94":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Manage this bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":241,"column":65},"end":{"line":241,"column":89}}}))
    + "</span>\n";
},"96":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Manage this user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":243,"column":65},"end":{"line":243,"column":90}}}))
    + "</span>\n";
},"98":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li role=\"none\" class=\"popover-menu-list-item link-item\">\n                        <a role=\"menuitem\" class=\"sidebar-popover-reactivate-user popover-menu-link hidden-for-spectators\" tabindex=\"0\">\n                            <i class=\"popover-menu-icon zulip-icon zulip-icon-user-plus\" aria-hidden=\"true\"></i>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(99, data, 0),"inverse":container.program(101, data, 0),"data":data,"loc":{"start":{"line":251,"column":28},"end":{"line":255,"column":35}}})) != null ? stack1 : "")
    + "                        </a>\n                    </li>\n";
},"99":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Reactivate this bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":252,"column":65},"end":{"line":252,"column":93}}}))
    + "</span>\n";
},"101":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Reactivate this user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":254,"column":65},"end":{"line":254,"column":94}}}))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu user-card-popover-actions no-auto-hide-right-sidebar-overlay\" id=\"user_card_popover\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <div class=\"popover-menu-user-header\">\n        <div class=\"popover-menu-user-avatar-container\">\n            <img class=\"popover-menu-user-avatar"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"user_is_guest"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":48},"end":{"line":4,"column":89}}})) != null ? stack1 : "")
    + "\" src=\""
    + alias4(alias3(alias2(depth0, "user_avatar", {"start":{"line":4,"column":98},"end":{"line":4,"column":109}} ), depth0))
    + "\" />\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depth0,"is_active"),lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":5,"column":33},"end":{"line":5,"column":45}}}),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":5,"column":18},"end":{"line":5,"column":46}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":7,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"popover-menu-user-info\">\n            <div class=\"popover-menu-user-full-name\" data-tippy-content=\""
    + alias4(alias3(alias2(depth0, "user_full_name", {"start":{"line":10,"column":75},"end":{"line":10,"column":89}} ), depth0))
    + "\">\n                "
    + ((stack1 = container.invokePartial(require("../../user_full_name.hbs"),depth0,{"name":"../../user_full_name","hash":{"name":lookupProperty(depth0,"user_full_name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":14,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"popover-menu-user-type\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":31,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n    <ul role=\"menu\" class=\"popover-menu-list\" data-user-id=\""
    + alias4(alias3(alias2(depth0, "user_id", {"start":{"line":35,"column":62},"end":{"line":35,"column":69}} ), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"status_content_available"),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":8},"end":{"line":62,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_me"),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":8},"end":{"line":94,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"is_me"),lookupProperty(depth0,"status_content_available"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":95,"column":14},"end":{"line":95,"column":49}}}),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":95,"column":8},"end":{"line":97,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_active"),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.program(48, data, 0),"data":data,"loc":{"start":{"line":98,"column":8},"end":{"line":119,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"spectator_view"),{"name":"if","hash":{},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":120,"column":8},"end":{"line":124,"column":15}}})) != null ? stack1 : "")
    + "        <li role=\"separator\" class=\"popover-menu-separator hidden-for-spectators\"></li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_active"),{"name":"if","hash":{},"fn":container.program(55, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":126,"column":8},"end":{"line":135,"column":15}}})) != null ? stack1 : "")
    + "        "
    + ((stack1 = container.invokePartial(require("./user_card_popover_custom_fields.hbs"),depth0,{"name":"./user_card_popover_custom_fields","hash":{"profile_fields":lookupProperty(depth0,"display_profile_fields")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        <li role=\"none\" class=\"popover-menu-list-item link-item hidden-for-spectators\">\n            <a role=\"menuitem\" class=\"popover-menu-link view_full_user_profile\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-account\" aria-hidden=\"true\"></i>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_me"),{"name":"if","hash":{},"fn":container.program(58, data, 0),"inverse":container.program(60, data, 0),"data":data,"loc":{"start":{"line":140,"column":16},"end":{"line":144,"column":23}}})) != null ? stack1 : "")
    + "            </a>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_send_private_message"),{"name":"if","hash":{},"fn":container.program(62, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":147,"column":8},"end":{"line":157,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_me"),{"name":"unless","hash":{},"fn":container.program(65, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":158,"column":8},"end":{"line":182,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_me"),{"name":"if","hash":{},"fn":container.program(75, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":183,"column":8},"end":{"line":190,"column":15}}})) != null ? stack1 : "")
    + "        <li role=\"separator\" class=\"popover-menu-separator hidden-for-spectators\"></li>\n        <li role=\"none\" class=\"popover-menu-list-item link-item\">\n            <a role=\"menuitem\" href=\""
    + alias4(alias3(alias2(depth0, "pm_with_url", {"start":{"line":193,"column":40},"end":{"line":193,"column":51}} ), depth0))
    + "\" class=\"narrow_to_private_messages popover-menu-link hidden-for-spectators\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-user\" aria-hidden=\"true\"></i>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_me"),{"name":"if","hash":{},"fn":container.program(77, data, 0),"inverse":container.program(79, data, 0),"data":data,"loc":{"start":{"line":195,"column":16},"end":{"line":199,"column":23}}})) != null ? stack1 : "")
    + "            </a>\n        </li>\n        <li role=\"none\" class=\"popover-menu-list-item link-item\">\n            <a role=\"menuitem\" href=\""
    + alias4(alias3(alias2(depth0, "sent_by_url", {"start":{"line":203,"column":40},"end":{"line":203,"column":51}} ), depth0))
    + "\" class=\"narrow_to_messages_sent popover-menu-link hidden-for-spectators\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-message-square\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias4(lookupProperty(helpers,"t").call(alias1,"View messages sent",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":205,"column":49},"end":{"line":205,"column":76}}}))
    + "</span>\n            </a>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"show_manage_section"),{"name":"if","hash":{},"fn":container.program(81, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":208,"column":8},"end":{"line":260,"column":15}}})) != null ? stack1 : "")
    + "    </ul>\n</div>\n";
},"usePartial":true,"useData":true});