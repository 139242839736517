var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                Code playgrounds are interactive in-browser development environments,\n                that are designed to make\n                it convenient to edit and debug code. Zulip <z-link-code-blocks>code blocks</z-link-code-blocks>\n                that are tagged with a programming language will have a button visible on\n                hover that allows users to open the code block on the code playground site.\n                \n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/code-blocks\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                For more examples and technical details, see the <z-link>help center documentation</z-link>\n                on adding code playgrounds.\n                \n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/code-blocks#code-playgrounds\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <form class=\"admin-playground-form\">\n            <div class=\"add-new-playground-box grey-box\">\n                <div class=\"new-playground-form wrapper\">\n                    <div class=\"settings-section-title\">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add a new code playground",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":24},"end":{"line":41,"column":58}}}))
    + "\n                        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/code-blocks#code-playgrounds"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </div>\n                    <div class=\"alert\" id=\"admin-playground-status\"></div>\n                    <div class=\"input-group\">\n                        <label for=\"playground_pygments_language\"> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Language",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":67},"end":{"line":46,"column":84}}}))
    + "</label>\n                        <input type=\"text\" id=\"playground_pygments_language\" class=\"settings_text_input\" name=\"pygments_language\" autocomplete=\"off\" placeholder=\"Rust\" />\n                    </div>\n                    <div class=\"input-group\">\n                        <label for=\"playground_name\"> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":54},"end":{"line":50,"column":67}}}))
    + "</label>\n                        <input type=\"text\" id=\"playground_name\" class=\"settings_text_input\" name=\"playground_name\" autocomplete=\"off\" placeholder=\"Rust playground\" />\n                    </div>\n                    <div class=\"input-group\">\n                        <label for=\"playground_url_template\"> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"URL template",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":62},"end":{"line":54,"column":83}}}))
    + "</label>\n                        <input type=\"text\" id=\"playground_url_template\" class=\"settings_text_input\" name=\"url_template\" placeholder=\"https://play.rust-lang.org/?code={code}\" />\n                    </div>\n                    <button type=\"submit\" id=\"submit_playground_button\" class=\"button rounded sea-green\">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add code playground",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":24},"end":{"line":58,"column":52}}}))
    + "\n                    </button>\n                </div>\n            </div>\n        </form>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <th class=\"actions\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":77,"column":40},"end":{"line":77,"column":56}}}))
    + "</th>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"playground-settings\" class=\"settings-section\" data-name=\"playground-settings\">\n    <div>\n        <p>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":11,"column":19}}})) != null ? stack1 : "")
    + "        </p>\n        <p>\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"For example, to configure a code playground for code blocks tagged as Rust,\n              you can set:",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":15,"column":30}}}))
    + "\n        </p>\n        <ul>\n            <li>\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Language",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":19,"column":33}}}))
    + ": <span class=\"rendered_markdown\"><code>Rust</code></span>\n            </li>\n            <li>\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":16},"end":{"line":22,"column":29}}}))
    + ": <span class=\"rendered_markdown\"><code>Rust playground</code></span>\n            </li>\n            <li>\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"URL template",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":25,"column":37}}}))
    + ": <span class=\"rendered_markdown\"><code>https://play.rust-lang.org/?code={code}</code></span>\n            </li>\n        </ul>\n        <p>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":12},"end":{"line":33,"column":19}}})) != null ? stack1 : "")
    + "        </p>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":8},"end":{"line":63,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"settings_panel_list_header\">\n            <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Code playgrounds",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":16},"end":{"line":66,"column":40}}}))
    + "</h3>\n            <input type=\"text\" class=\"search filter_text_input\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter code playgrounds",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":77},"end":{"line":67,"column":109}}}))
    + "\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter code playgrounds",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":123},"end":{"line":67,"column":155}}}))
    + "\"/>\n        </div>\n\n        <div class=\"progressive-table-wrapper\" data-simplebar data-simplebar-tab-index=\"-1\">\n            <table class=\"table table-striped wrapped-table admin_playgrounds_table\">\n                <thead class=\"table-sticky-headers\">\n                    <th class=\"active\" data-sort=\"alphabetic\" data-sort-prop=\"pygments_language\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Language",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":73,"column":97},"end":{"line":73,"column":114}}}))
    + "</th>\n                    <th data-sort=\"alphabetic\" data-sort-prop=\"name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":74,"column":69},"end":{"line":74,"column":82}}}))
    + "</th>\n                    <th data-sort=\"alphabetic\" data-sort-prop=\"url_template\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"URL template",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":75,"column":77},"end":{"line":75,"column":98}}}))
    + "</th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":20},"end":{"line":78,"column":27}}})) != null ? stack1 : "")
    + "                </thead>\n                <tbody id=\"admin_playgrounds_table\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No playgrounds configured.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":80,"column":64},"end":{"line":80,"column":99}}}))
    + "\" data-search-results-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No playgrounds match your current filter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":80,"column":128},"end":{"line":80,"column":178}}}))
    + "\"></tbody>\n            </table>\n        </div>\n    </div>\n</div>\n";
},"1_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link-code-blocks"],"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":10,"column":154}}}) || fn;
  return fn;
  }

,"useDecorators":true,"4_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link"],"data":data,"loc":{"start":{"line":32,"column":16},"end":{"line":32,"column":159}}}) || fn;
  return fn;
  }

,"usePartial":true,"useData":true,"useDepths":true});