var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + container.escapeExpression(lookupProperty(helpers,"tooltip_hotkey_hints").call(depth0 != null ? depth0 : (container.nullContext || {}),"Esc",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":34}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + container.escapeExpression(lookupProperty(helpers,"tooltip_hotkey_hints").call(depth0 != null ? depth0 : (container.nullContext || {}),"Ctrl","[",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":7,"column":39}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Go to home view",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":9},"end":{"line":2,"column":32}}}))
    + " ("
    + alias2((((helper = lookupProperty(helpers,"home_view") || container.strict(depth0, "home_view", {"start":{"line":2,"column":36},"end":{"line":2,"column":45}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"home_view","hash":{},"data":data,"loc":{"start":{"line":2,"column":34},"end":{"line":2,"column":47}}}) : helper)))
    + ")</div>\n</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"escape_navigates_to_home_view"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":8,"column":7}}})) != null ? stack1 : "");
},"useData":true});