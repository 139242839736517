var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button class=\"upload_banner_cancel_button\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":52},"end":{"line":5,"column":67}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"upload_banner file_"
    + alias4((((helper = lookupProperty(helpers,"file_id") || alias1(depth0, "file_id", {"start":{"line":1,"column":33},"end":{"line":1,"column":40}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"file_id","hash":{},"data":data,"loc":{"start":{"line":1,"column":31},"end":{"line":1,"column":42}}}) : helper)))
    + " main-view-banner "
    + alias4((((helper = lookupProperty(helpers,"banner_type") || alias1(depth0, "banner_type", {"start":{"line":1,"column":62},"end":{"line":1,"column":73}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"banner_type","hash":{},"data":data,"loc":{"start":{"line":1,"column":60},"end":{"line":1,"column":75}}}) : helper)))
    + "\">\n    <div class=\"moving_bar\"></div>\n    <p class=\"upload_msg banner_content\">"
    + alias4((((helper = lookupProperty(helpers,"banner_text") || alias1(depth0, "banner_text", {"start":{"line":3,"column":43},"end":{"line":3,"column":54}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"banner_text","hash":{},"data":data,"loc":{"start":{"line":3,"column":41},"end":{"line":3,"column":56}}}) : helper)))
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"is_upload_process_tracker"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + "    <a role=\"button\" class=\"zulip-icon zulip-icon-close main-view-banner-close-button\"></a>\n</div>\n";
},"useData":true});