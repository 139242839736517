var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"micromodal\" id=\"send_later_modal\" aria-hidden=\"true\">\n    <div class=\"modal__overlay\" tabindex=\"-1\">\n        <div class=\"modal__container\" role=\"dialog\" aria-modal=\"true\" aria-labelledby=\"send_later_modal_label\">\n            <header class=\"modal__header\">\n                <h1 class=\"modal__title\" id=\"send_later_modal_label\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Schedule message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":45}}}))
    + "\n                </h1>\n                <button class=\"modal__close\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Close modal",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":57},"end":{"line":8,"column":77}}}))
    + "\" data-micromodal-close></button>\n            </header>\n            <main class=\"modal__content\">\n                "
    + ((stack1 = container.invokePartial(require("./send_later_modal_options.hbs"),depth0,{"name":"send_later_modal_options","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </main>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});