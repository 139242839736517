var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"image-disabled "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_editable_by_current_user"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":31},"end":{"line":3,"column":77}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"image-hover-background\"></div>\n        <span class=\"image-disabled-text flex\" aria-label=\""
    + alias4((((helper = lookupProperty(helpers,"disabled_text") || alias2(depth0, "disabled_text", {"start":{"line":5,"column":62},"end":{"line":5,"column":75}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"disabled_text","hash":{},"data":data,"loc":{"start":{"line":5,"column":59},"end":{"line":5,"column":78}}}) : helper)))
    + "\" role=\"button\" tabindex=\"0\">\n            "
    + alias4((((helper = lookupProperty(helpers,"disabled_text") || alias2(depth0, "disabled_text", {"start":{"line":6,"column":15},"end":{"line":6,"column":28}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"disabled_text","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":31}}}) : helper)))
    + "\n        </span>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "hide";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\""
    + alias4((((helper = lookupProperty(helpers,"widget") || alias1(depth0, "widget", {"start":{"line":1,"column":11},"end":{"line":1,"column":17}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"widget","hash":{},"data":data,"loc":{"start":{"line":1,"column":9},"end":{"line":1,"column":19}}}) : helper)))
    + "-upload-widget\" class=\"inline-block image_upload_widget\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"disabled_text"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"image_upload_button "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias2,lookupProperty(depth0,"is_editable_by_current_user"),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":36},"end":{"line":10,"column":90}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"image-hover-background\"></div>\n        <button class=\"image-delete-button\" aria-label=\""
    + alias4((((helper = lookupProperty(helpers,"delete_text") || alias1(depth0, "delete_text", {"start":{"line":12,"column":59},"end":{"line":12,"column":70}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"delete_text","hash":{},"data":data,"loc":{"start":{"line":12,"column":56},"end":{"line":12,"column":73}}}) : helper)))
    + "\" role=\"button\" tabindex=\"0\">\n            &times;\n        </button>\n        <span class=\"image-delete-text\" aria-label=\""
    + alias4((((helper = lookupProperty(helpers,"delete_text") || alias1(depth0, "delete_text", {"start":{"line":15,"column":55},"end":{"line":15,"column":66}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"delete_text","hash":{},"data":data,"loc":{"start":{"line":15,"column":52},"end":{"line":15,"column":69}}}) : helper)))
    + "\" tabindex=\"0\">\n            "
    + alias4((((helper = lookupProperty(helpers,"delete_text") || alias1(depth0, "delete_text", {"start":{"line":16,"column":15},"end":{"line":16,"column":26}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"delete_text","hash":{},"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":16,"column":29}}}) : helper)))
    + "\n        </span>\n        <span class=\"image-upload-text\" aria-label=\""
    + alias4((((helper = lookupProperty(helpers,"upload_text") || alias1(depth0, "upload_text", {"start":{"line":18,"column":55},"end":{"line":18,"column":66}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"upload_text","hash":{},"data":data,"loc":{"start":{"line":18,"column":52},"end":{"line":18,"column":69}}}) : helper)))
    + "\" role=\"button\" tabindex=\"0\">\n            "
    + alias4((((helper = lookupProperty(helpers,"upload_text") || alias1(depth0, "upload_text", {"start":{"line":19,"column":15},"end":{"line":19,"column":26}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"upload_text","hash":{},"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":19,"column":29}}}) : helper)))
    + "\n        </span>\n        <span class=\"upload-spinner-background\">\n            <img class=\"image_upload_spinner\" src=\"" + require("../../images/loading/tail-spin.svg") + "\" alt=\"\" />\n        </span>\n    </div>\n    <img class=\"image-block\" src=\""
    + alias4((((helper = lookupProperty(helpers,"image") || alias1(depth0, "image", {"start":{"line":25,"column":37},"end":{"line":25,"column":42}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"image","hash":{},"data":data,"loc":{"start":{"line":25,"column":34},"end":{"line":25,"column":45}}}) : helper)))
    + "\"/>\n    <input type=\"file\" name=\"file_input\" class=\"notvisible image_file_input\"  value=\""
    + alias4((((helper = lookupProperty(helpers,"upload_text") || alias1(depth0, "upload_text", {"start":{"line":26,"column":88},"end":{"line":26,"column":99}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"upload_text","hash":{},"data":data,"loc":{"start":{"line":26,"column":85},"end":{"line":26,"column":102}}}) : helper)))
    + "\" />\n    <div class=\"image_file_input_error text-error\"></div>\n</div>\n";
},"useData":true});