var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2((((helper = lookupProperty(helpers,"content") || container.strict(depth0, "content", {"start":{"line":1,"column":2},"end":{"line":1,"column":9}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"content","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":11}}}) : helper)))
    + "\n"
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"S",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":2,"column":28}}}))
    + "\n";
},"useData":true});