var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "required-field-wrapper";
},"3":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"5":function(container,depth0,helpers,partials,data) {
    return "empty-required-field";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <textarea maxlength=\"500\" class=\"custom_user_field_value settings_textarea\" name=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"field"), "id", {"start":{"line":10,"column":93},"end":{"line":10,"column":101}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(lookupProperty(depth0,"field_value"), "value", {"start":{"line":10,"column":109},"end":{"line":10,"column":126}} ), depth0))
    + "</textarea>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_select_field"),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":32,"column":8}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <select class=\"custom_user_field_value "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"for_manage_user_modal"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":12,"column":47},"end":{"line":12,"column":118}}})) != null ? stack1 : "")
    + " bootstrap-focus-style\" name=\""
    + container.escapeExpression(container.lambda(container.strict(lookupProperty(depth0,"field"), "id", {"start":{"line":12,"column":151},"end":{"line":12,"column":159}} ), depth0))
    + "\">\n            <option value=\"\"></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"field_choices"),{"name":"each","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":16,"column":21}}})) != null ? stack1 : "")
    + "        </select>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "modal_select";
},"13":function(container,depth0,helpers,partials,data) {
    return "settings_select";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value=\""
    + alias3(alias2(alias1(depth0, "value", {"start":{"line":15,"column":34},"end":{"line":15,"column":44}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"selected"),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":49},"end":{"line":15,"column":85}}})) != null ? stack1 : "")
    + ">"
    + alias3(alias2(alias1(depth0, "text", {"start":{"line":15,"column":89},"end":{"line":15,"column":98}} ), depth0))
    + "</option>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "selected";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_user_field"),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":32,"column":8}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"pill-container person_picker\" name=\""
    + container.escapeExpression(container.lambda(container.strict(lookupProperty(depth0,"field"), "id", {"start":{"line":19,"column":59},"end":{"line":19,"column":67}} ), depth0))
    + "\">\n            <div class=\"input\" contenteditable=\"true\"></div>\n        </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_date_field"),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":22,"column":8},"end":{"line":32,"column":8}}})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <input class=\"custom_user_field_value datepicker "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"for_manage_user_modal"),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":23,"column":57},"end":{"line":23,"column":136}}})) != null ? stack1 : "")
    + "\" name=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"field"), "id", {"start":{"line":23,"column":147},"end":{"line":23,"column":155}} ), depth0))
    + "\" data-field-id=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"field"), "id", {"start":{"line":23,"column":178},"end":{"line":23,"column":186}} ), depth0))
    + "\" type=\"text\"\n          value=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"field_value"), "value", {"start":{"line":24,"column":20},"end":{"line":24,"column":37}} ), depth0))
    + "\" />\n        <span class=\"remove_date\"><i class=\"fa fa-close\"></i></span>\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "modal_text_input";
},"25":function(container,depth0,helpers,partials,data) {
    return "settings_text_input";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_url_field"),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(33, data, 0),"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":32,"column":8}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <input class=\"custom_user_field_value "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"for_manage_user_modal"),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.program(31, data, 0),"data":data,"loc":{"start":{"line":27,"column":46},"end":{"line":27,"column":123}}})) != null ? stack1 : "")
    + "\" name=\""
    + alias4(alias3(alias2(lookupProperty(depth0,"field"), "id", {"start":{"line":27,"column":134},"end":{"line":27,"column":142}} ), depth0))
    + "\" type=\""
    + alias4((((helper = lookupProperty(helpers,"field_type") || alias2(depth0, "field_type", {"start":{"line":27,"column":156},"end":{"line":27,"column":166}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"field_type","hash":{},"data":data,"loc":{"start":{"line":27,"column":153},"end":{"line":27,"column":169}}}) : helper)))
    + "\" value=\""
    + alias4(alias3(alias2(lookupProperty(depth0,"field_value"), "value", {"start":{"line":27,"column":181},"end":{"line":27,"column":198}} ), depth0))
    + "\" maxlength=\"2048\" />\n";
},"29":function(container,depth0,helpers,partials,data) {
    return "modal_url_input";
},"31":function(container,depth0,helpers,partials,data) {
    return "settings_url_input";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_pronouns_field"),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.program(36, data, 0),"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":32,"column":8}}})) != null ? stack1 : "");
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <input class=\"custom_user_field_value pronouns_type_field "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"for_manage_user_modal"),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":29,"column":66},"end":{"line":29,"column":145}}})) != null ? stack1 : "")
    + "\" name=\""
    + alias4(alias3(alias2(lookupProperty(depth0,"field"), "id", {"start":{"line":29,"column":156},"end":{"line":29,"column":164}} ), depth0))
    + "\" type=\""
    + alias4((((helper = lookupProperty(helpers,"field_type") || alias2(depth0, "field_type", {"start":{"line":29,"column":178},"end":{"line":29,"column":188}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"field_type","hash":{},"data":data,"loc":{"start":{"line":29,"column":175},"end":{"line":29,"column":191}}}) : helper)))
    + "\" value=\""
    + alias4(alias3(alias2(lookupProperty(depth0,"field_value"), "value", {"start":{"line":29,"column":203},"end":{"line":29,"column":220}} ), depth0))
    + "\" maxlength=\"50\" />\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <input class=\"custom_user_field_value "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"for_manage_user_modal"),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":31,"column":46},"end":{"line":31,"column":125}}})) != null ? stack1 : "")
    + "\" name=\""
    + alias4(alias3(alias2(lookupProperty(depth0,"field"), "id", {"start":{"line":31,"column":136},"end":{"line":31,"column":144}} ), depth0))
    + "\" type=\""
    + alias4((((helper = lookupProperty(helpers,"field_type") || alias2(depth0, "field_type", {"start":{"line":31,"column":158},"end":{"line":31,"column":168}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"field_type","hash":{},"data":data,"loc":{"start":{"line":31,"column":155},"end":{"line":31,"column":171}}}) : helper)))
    + "\" value=\""
    + alias4(alias3(alias2(lookupProperty(depth0,"field_value"), "value", {"start":{"line":31,"column":183},"end":{"line":31,"column":200}} ), depth0))
    + "\" maxlength=\"50\" />\n        ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"custom_user_field\" name=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"field"), "name", {"start":{"line":1,"column":40},"end":{"line":1,"column":50}} ), depth0))
    + "\" data-field-id=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"field"), "id", {"start":{"line":1,"column":73},"end":{"line":1,"column":81}} ), depth0))
    + "\">\n    <span class=\"custom-user-field-label-wrapper "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(lookupProperty(depth0,"field"),"required"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":49},"end":{"line":2,"column":100}}})) != null ? stack1 : "")
    + "\">\n        <label class=\"settings-field-label inline-block\" for=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"field"), "name", {"start":{"line":3,"column":65},"end":{"line":3,"column":75}} ), depth0))
    + "\" class=\"title\">"
    + alias3(alias2(alias1(lookupProperty(depth0,"field"), "name", {"start":{"line":3,"column":97},"end":{"line":3,"column":107}} ), depth0))
    + "</label>\n        <span class=\"required-symbol "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(depth0,"is_empty_required_field"),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":37},"end":{"line":4,"column":89}}})) != null ? stack1 : "")
    + "\"> *</span>\n    </span>\n    <div class=\"alert-notification custom-field-status\"></div>\n    <div class=\"settings-profile-user-field-hint\">"
    + alias3(alias2(alias1(lookupProperty(depth0,"field"), "hint", {"start":{"line":7,"column":53},"end":{"line":7,"column":63}} ), depth0))
    + "</div>\n    <div class=\"settings-profile-user-field "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_empty_required_field"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":44},"end":{"line":8,"column":102}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_long_text_field"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":32,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});