var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":9,"column":23}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    Notifications are based on your configuration for <z-stream-name></z-stream-name>.\n                    \n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<span class=\"tooltip-privacy-icon\">"
    + ((stack1 = container.invokePartial(require("./stream_privacy.hbs"),depth0,{"name":"stream_privacy","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<span class=\"privacy-tooltip-stream-name\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "name", {"start":{"line":8,"column":148},"end":{"line":8,"column":152}} ), depth0))
    + "</span></span>";
},"5":function(container,depth0,helpers,partials,data) {
    return "                "
    + container.escapeExpression(container.lambda(container.strict(depth0, "current_visibility_policy_str", {"start":{"line":11,"column":18},"end":{"line":11,"column":47}} ), depth0))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"change_visibility_policy_button_tooltip\">\n    <strong>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Configure topic notifications",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":12},"end":{"line":2,"column":50}}}))
    + "</strong>\n    <div class=\"tooltip-inner-content italic\">\n        <span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_render_privacy_icon"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":12,"column":19}}})) != null ? stack1 : "")
    + "        </span>\n    </div>\n</div>\n";
},"2_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-stream-name"],"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":179}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});