var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <tr>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"note_html"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":16,"column":28},"end":{"line":21,"column":35}}})) != null ? stack1 : "")
    + "                        </tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <td colspan=\"2\">"
    + ((stack1 = (((helper = lookupProperty(helpers,"note_html") || container.strict(depth0, "note_html", {"start":{"line":17,"column":47},"end":{"line":17,"column":56}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"note_html","hash":{},"data":data,"loc":{"start":{"line":17,"column":44},"end":{"line":17,"column":59}}}) : helper))) != null ? stack1 : "")
    + "</td>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <td><div class=\"preserve_spaces\">"
    + container.escapeExpression((((helper = lookupProperty(helpers,"markdown") || alias1(depth0, "markdown", {"start":{"line":19,"column":63},"end":{"line":19,"column":71}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"markdown","hash":{},"data":data,"loc":{"start":{"line":19,"column":61},"end":{"line":19,"column":73}}}) : helper)))
    + "</div> "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"usage_html"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":80},"end":{"line":19,"column":121}}})) != null ? stack1 : "")
    + "</td>\n                            <td class=\"rendered_markdown\">"
    + ((stack1 = (((helper = lookupProperty(helpers,"output_html") || alias1(depth0, "output_html", {"start":{"line":20,"column":61},"end":{"line":20,"column":72}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"output_html","hash":{},"data":data,"loc":{"start":{"line":20,"column":58},"end":{"line":20,"column":75}}}) : helper))) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"effect_html"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":76},"end":{"line":20,"column":119}}})) != null ? stack1 : "")
    + "</td>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (((helper = lookupProperty(helpers,"usage_html") || container.strict(depth0, "usage_html", {"start":{"line":19,"column":101},"end":{"line":19,"column":111}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"usage_html","hash":{},"data":data,"loc":{"start":{"line":19,"column":98},"end":{"line":19,"column":114}}}) : helper))) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (((helper = lookupProperty(helpers,"effect_html") || container.strict(depth0, "effect_html", {"start":{"line":20,"column":98},"end":{"line":20,"column":109}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"effect_html","hash":{},"data":data,"loc":{"start":{"line":20,"column":95},"end":{"line":20,"column":112}}}) : helper))) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"overlay-modal hide\" id=\"message-formatting\" tabindex=\"-1\" role=\"dialog\"\n  aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Message formatting",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":14},"end":{"line":2,"column":41}}}))
    + "\">\n    <div class=\"overlay-scroll-container\" data-simplebar data-simplebar-tab-index=\"-1\" data-simplebar-auto-hide=\"false\">\n        <div id=\"markdown-instructions\">\n            <table class=\"table table-striped table-rounded table-bordered help-table\">\n                <thead>\n                    <tr>\n                        <th id=\"message-formatting-first-header\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"You type",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":65},"end":{"line":8,"column":82}}}))
    + "</th>\n                        <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"You get",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":28},"end":{"line":9,"column":44}}}))
    + "</th>\n                    </tr>\n                </thead>\n\n                <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"markdown_help_rows"),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":23,"column":29}}})) != null ? stack1 : "")
    + "                </tbody>\n            </table>\n        </div>\n        <hr />\n        <a href=\"/help/format-your-message-using-markdown\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Detailed message formatting documentation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":101},"end":{"line":28,"column":151}}}))
    + "</a>\n    </div>\n</div>\n";
},"useData":true});