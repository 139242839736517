var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            For more examples, see the <z-link>help center documentation</z-link>\n            on adding linkifiers.\n            \n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/add-a-custom-linkifier\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <form class=\"admin-linkifier-form\">\n        <div class=\"add-new-linkifier-box grey-box\">\n            <div class=\"new-linkifier-form wrapper\">\n                <div class=\"settings-section-title new-linkifier-section-title\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add a new linkifier",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":20},"end":{"line":35,"column":48}}}))
    + "\n                    "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/add-a-custom-linkifier"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n                <div class=\"alert\" id=\"admin-linkifier-status\"></div>\n                <div class=\"input-group\">\n                    <label for=\"linkifier_pattern\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Pattern",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":51},"end":{"line":40,"column":67}}}))
    + "</label>\n                    <input type=\"text\" id=\"linkifier_pattern\" class=\"settings_text_input\" name=\"pattern\" placeholder=\"#(?P<id>[0-9]+)\" />\n                    <div class=\"alert\" id=\"admin-linkifier-pattern-status\"></div>\n                </div>\n                <div class=\"input-group\">\n                    <label for=\"linkifier_template\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"URL template",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":52},"end":{"line":45,"column":73}}}))
    + "</label>\n                    <input type=\"text\" id=\"linkifier_template\" class=\"settings_text_input\" name=\"url_template\" placeholder=\"https://github.com/zulip/zulip/issues/{id}\" />\n                    <div class=\"alert\" id=\"admin-linkifier-template-status\"></div>\n                </div>\n                <button type=\"submit\" class=\"button rounded sea-green\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add linkifier",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":20},"end":{"line":50,"column":42}}}))
    + "\n                </button>\n            </div>\n        </div>\n    </form>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <th class=\"actions\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":36},"end":{"line":69,"column":52}}}))
    + "</th>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"linkifier-settings\" class=\"settings-section\" data-name=\"linkifier-settings\">\n    <p>\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Configure regular expression patterns that will be used to\n          automatically transform any matching text in Zulip messages\n          and topics into links.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":5,"column":36}}}))
    + "\n    </p>\n    <p>\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Linkifiers make it easy to refer to issues or tickets in\n          third party issue trackers, like GitHub, Salesforce, Zendesk,\n          and others. For instance, you can add a linkifier that\n          automatically turns #2468 into a link to the GitHub issue\n          in the Zulip repository with:",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":12,"column":43}}}))
    + "\n    </p>\n    <ul>\n        <li>\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Pattern",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":16,"column":28}}}))
    + ": <span class=\"rendered_markdown\"><code>#(?P&lt;id&gt;[0-9]+)</code></span>\n        </li>\n        <li>\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"URL template",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":19,"column":33}}}))
    + ": <span class=\"rendered_markdown\"><code>https://github.com/zulip/zulip/issues/{id}</code></span>\n        </li>\n    </ul>\n    <p>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":27,"column":15}}})) != null ? stack1 : "")
    + "    </p>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":4},"end":{"line":55,"column":11}}})) != null ? stack1 : "")
    + "\n    <div class=\"settings_panel_list_header\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Linkifiers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":12},"end":{"line":58,"column":30}}}))
    + "</h3>\n        <div class=\"alert-notification edit-linkifier-status\" id=\"linkifier-field-status\"></div>\n        <input type=\"text\" class=\"search filter_text_input\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter linkifiers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":73},"end":{"line":60,"column":99}}}))
    + "\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter linkifiers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":113},"end":{"line":60,"column":139}}}))
    + "\"/>\n    </div>\n\n    <div class=\"progressive-table-wrapper\" data-simplebar data-simplebar-tab-index=\"-1\">\n        <table class=\"table table-striped wrapped-table admin_linkifiers_table\">\n            <thead class=\"table-sticky-headers\">\n                <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Pattern",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":20},"end":{"line":66,"column":36}}}))
    + "</th>\n                <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"URL template",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":20},"end":{"line":67,"column":41}}}))
    + "</th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":16},"end":{"line":70,"column":23}}})) != null ? stack1 : "")
    + "            </thead>\n            <tbody id=\"admin_linkifiers_table\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No linkifiers configured.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":72,"column":59},"end":{"line":72,"column":93}}}))
    + "\" data-search-results-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No linkifiers match your current filter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":72,"column":122},"end":{"line":72,"column":171}}}))
    + "\"></tbody>\n        </table>\n    </div>\n</div>\n";
},"1_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link"],"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":26,"column":149}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});