var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            Configure the <z-link>default personal preference settings</z-link>\n            for new users joining your organization.\n            \n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/configure-default-new-user-settings\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"realm-user-default-settings\" class=\"settings-section\" data-name=\"organization-level-user-defaults\">\n    <div class=\"tip\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    "
    + ((stack1 = container.invokePartial(require("./display_settings.hbs"),depth0,{"name":"display_settings","hash":{"full_name":lookupProperty(depth0,"full_name"),"for_realm_settings":true,"prefix":"realm_"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = container.invokePartial(require("./notification_settings.hbs"),depth0,{"name":"notification_settings","hash":{"for_realm_settings":true,"prefix":"realm_"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    <div class=\"privacy_settings settings-subsection-parent\">\n        <div class=\"subsection-header inline-block\">\n            <h3 class=\"inline-block\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Privacy settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":37},"end":{"line":15,"column":62}}}))
    + "</h3>\n            "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":false,"section_name":"privacy-setting"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n        "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"prefix":"realm_","label":lookupProperty(lookupProperty(depth0,"settings_label"),"realm_send_private_typing_notifications"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"send_private_typing_notifications"),"setting_name":"send_private_typing_notifications"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"prefix":"realm_","label":lookupProperty(lookupProperty(depth0,"settings_label"),"realm_send_stream_typing_notifications"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"send_stream_typing_notifications"),"setting_name":"send_stream_typing_notifications"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"prefix":"realm_","help_link":"/help/status-and-availability","label_parens_text":lookupProperty(lookupProperty(depth0,"settings_label"),"realm_presence_enabled_parens_text"),"label":lookupProperty(lookupProperty(depth0,"settings_label"),"realm_presence_enabled"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"presence_enabled"),"setting_name":"presence_enabled"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"help_link":"/help/read-receipts","prefix":"realm_","label":lookupProperty(lookupProperty(depth0,"settings_label"),"realm_send_read_receipts"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"send_read_receipts"),"setting_name":"send_read_receipts"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n        <div class=\"input-group\">\n            <label for=\"email_address_visibility\" class=\"settings-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can access user's email address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":79},"end":{"line":43,"column":123}}}))
    + "\n                "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/configure-email-visibility"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </label>\n            <select name=\"email_address_visibility\" class=\"email_address_visibility prop-element settings_select bootstrap-focus-style\" data-setting-widget-type=\"number\"\n              id=\"realm_email_address_visibility\">\n                "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"email_address_visibility_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </select>\n        </div>\n    </div>\n\n    <div class=\"other_settings settings-subsection-parent\">\n        <div class=\"subsection-header inline-block\">\n            <h3 class=\"inline-block\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Other settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":37},"end":{"line":55,"column":60}}}))
    + "</h3>\n            "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":false,"section_name":"other-setting"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n        "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"prefix":"realm_","label":lookupProperty(lookupProperty(depth0,"settings_label"),"realm_enter_sends"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"enter_sends"),"setting_name":"enter_sends"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"1_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link"],"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":163}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});